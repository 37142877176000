<template>
  <div class="compare-content">
    <div
      ref="comparePreview"
      v-if="fileType !== 'audio'"
      :visible="dialogVisiable"
      class="preview-dialog"
    >
      <!-- 预览视频 video -->
      <div
        v-if="dialogVisiable && fileType === 'video'"
        class="custom-content-video t-c"
      >
        <div class="prism-player" ref="plays" id="player-con"></div>
        <!-- <div class="close-dialog" @click="closeDialog">
          <i class="el-icon-close"></i> {{ this.$t("el.common.closeDialog") }}
        </div> -->
        <!-- <video :src="playUrl" /> -->
      </div>
      <!-- 预览视频 video -->
      <!-- 预览图片 image -->
      <div
        v-if="dialogVisiable && fileType === 'image'"
        class="custom-content-image t-c"
      >
        <div class="img-wrap">
          <el-image :src="playUrl"></el-image>
        </div>
      </div>
      <!-- 预览图片 image -->
      <!-- 预览wps -->
      <div
        v-else-if="dialogVisiable && fileType === 'wpsFile'"
        class="custom-content-wps t-c"
      >
        <iframe
          id="iframeWps"
          :src="iframeSrc"
          style="height: 100%"
          width="100%"
          scrolling="no"
          frameborder="0"
        ></iframe>

        <!-- <div class="close-dialog" @click="closeDialog">
          <i class="el-icon-close"></i> {{ this.$t("el.common.closeDialog") }}
        </div> -->
      </div>
      <!-- 预览wps 结束-->
    </div>
    <!-- 音频 -->
    <div
      v-drag="{ set: () => {}, data: dragData }"
      v-if="dialogVisiable && fileType === 'audio'"
      class="preview-dialog audio-dialog"
    >
      <!-- 预览音频 start -->
      <div class="custom-content-audio t-c">
        <div class="prism-player" ref="plays" id="player-con"></div>
        <!-- <div class="close-dialog" @click="closeDialog">
          <i class="el-icon-close"></i>
        </div> -->
        <div class="audio-resource-name single-line">
          {{ file.resourceName }}
        </div>
      </div>
      <!-- 预览音频 end -->
    </div>
  </div>
</template>

<script>
import drag from "@/directive/drag/index";
import { handparams } from "@/utils";
export default {
  data() {
    return {
      dialogVisiable: false,
      optionsData: {},
      dragData: {
        direction: "all",
        scope: "screen", // screen 屏幕范围，none 不限制
      },

      iframeSrc: "",
      player: null, // 播放器对象
    };
  },
  directives: {
    drag,
  },
  components: {
    // CDialog,
  },
  props: {
    showPreviewDialog: {
      type: Boolean,
      default: false,
    },
    playUrl: {
      type: String,
      default: "",
    },
    file: {
      type: Object,
      default: () => {},
    },
    fileType: {
      type: String,
      default: "",
    },
    appendToBody: {
      type: Boolean,
      default: false,
    },
    courseId: {
      type: String,
      default: "",
    },
  },
  watch: {
    file(newVal, oldVal) {
      if (oldVal) {
        this.initData();
      }
    },
    // 各类型弹窗初始化配置
    showPreviewDialog(newVal, oldVal) {
      this.dialogVisiable = newVal;
      console.log("this.dialogVisiable==", this.dialogVisiable);
      if (newVal) {
        this.initData();
      }
    },
    playUrl(newVal, oldVal) {
      this.fileUrl = newVal;
    },
  },
  created() {
    console.log("this.file===", this.file);
    // this.courseId = this.$route.query.courseId;
  },
  methods: {
    closeDialog() {
      //   this.player.pause();
      this.player && this.player.dispose();
      this.player = null;
      this.$emit("close-dialog");
    },

    initData() {
      if (!this.courseId) {
        this.courseId = this.file.courseId;
        console.log("this.courseId====", this.courseId);
      }
      if (this.fileType === "video") {
        this.optionsData = {
          "show-close": false,
          width: "80%",
          "custom-class": "custom-class-video",
        };
        this.initVideo();
      } else if (this.fileType === "audio") {
        this.optionsData = {
          "show-close": false,
          modal: false,
          width: "60%",
          "custom-class": "custom-class-audio",
        };
        this.initVideo();
      } else if (this.fileType === "wpsFile") {
        this.courseware = this.file;
        console.log("this.file.format===", this.file.format);
        this.showPPTpages =
          ["ppt", "pptx", "PPT", "PPTX"].indexOf(this.file.format) > -1;

        this.iframeSrc = `${process.env.VUE_APP_BASE_URL}PreviewWpsDialog?showPPTpages=${this.showPPTpages}&format=${this.file.format}&resourceId=${this.file.resourceId}&version=${this.file.version}&resourceType=${this.file.resourceType}&courseId=${this.courseId}&fileSource=bk`;

        console.log("this.iframeSrc===", this.iframeSrc);
      }
    },

    // 获取到视频地址之后的加载
    initVideo() {
      let that = this;
      console.log(document.getElementById("player-con"));
      this.$nextTick(() => {
        // eslint-disable-next-line no-undef
        this.player = new Aliplayer(
          {
            id: "player-con", // 播放器外层容器的dom元素id
            source: this.fileUrl, // 视频播放地址url
            width: "100%",
            height: "100%",
            autoplay: false,
            isLive: false,
            rePlay: false,
            playsinline: true,
            components: [
              {
                name: "RateComponent",
                // eslint-disable-next-line no-undef
                type: AliPlayerComponent.RateComponent,
              },
            ],
            preload: true,
            language: "zh-cn",
            controlBarVisibility: "hover",
            useH5Prism: true, // 指定使用H5播放器
            skinLayout: [
              {
                name: "bigPlayButton",
                align: "cc",
                x: 30,
                y: 80,
              },
              {
                name: "errorDisplay",
                align: "tlabs",
                x: 0,
                y: 0,
              },
              {
                name: "infoDisplay",
              },
              {
                name: "controlBar",
                align: "blabs",
                x: 0,
                y: 0,
                children: [
                  { name: "progress", align: "blabs", x: 0, y: 44 }, // 播放进度条
                  { name: "playButton", align: "tl", x: 15, y: 12 }, // 播放开始暂停按钮
                  { name: "timeDisplay", align: "tl", x: 10, y: 7 }, // 播放视频时间
                  {
                    name: this.fileType === "audio" ? "" : "fullScreenButton", // 全屏按钮
                    align: "tr",
                    x: 10,
                    y: 10,
                  },
                  {
                    name: "volume", // 音量
                    align: "tr",
                    x: 5,
                    y: 10,
                  },
                ],
              },
            ],
          },
          function (player) {
            console.log("The player is created");
            console.log("player=======", player);

            player.on("ready", that.playerReady);
          }
        );
        setTimeout(() => {
          console.log("getStatus====", this.player.getStatus());
          if (this.player.getStatus() === "loading") {
            that.$message.error(this.$t("el.common.unableLoad"));
            // that.dialogVisiable = false;
          }
        }, 10000);
      });
    },
    playerReady(player) {
      // console.log("playerReady=======", player);
      // console.log("_duration====", this.player._duration);
    },
    fullScreenHandle() {
      console.log("fullScreenHandle================");
    },
  },
};
</script>

<style lang="less" scoped>
.compare-content {
  width: 50%;
  height: 100%;
  margin-left: 0;
  // margin-right: 0;
  .preview-dialog {
    height: 100%;
    /deep/ .el-dialog__header {
      padding: 0 0 0;
    }
    /deep/ .el-dialog__body {
      padding: 0 0 0 0 !important;
      color: #606266;
      font-size: 14px;
      word-break: break-all;
      height: 100%;
    }
    // 视频 css
    .custom-content-video {
      width: 100%;
      height: 100%;
      .close-dialog {
        margin: 20px auto 0;
        width: fit-content;
        padding-left: 10px;
        padding-right: 10px;
        height: 32px;
        border-radius: 20px;
        background: white;

        line-height: 32px;
        color: #333333;
        font-size: 14px;
        cursor: pointer;
      }
    }
    .custom-content-image {
      width: 100%;
      height: 100vh;
      .img-wrap {
        height: 100vh;
        overflow: scroll;
      }

      .el-image {
        width: 100%;
      }
    }
    .custom-content-wps {
      width: 100%;
      height: 100%;
      .close-dialog {
        margin: 20px auto 0;
        width: fit-content;
        padding-left: 10px;
        padding-right: 10px;
        height: 32px;
        border-radius: 20px;
        background: white;

        line-height: 32px;
        color: #333333;
        font-size: 14px;
        cursor: pointer;
      }
    }
    /deep/ .custom-class-video {
      height: 70%;
      background: #000000;
    }

    /deep/ .custom-class-wps {
      height: 85%;
      background: #ffffff;
    }
  }
  .audio-dialog {
    position: fixed;
    width: 500px;
    background: #000000;
    border-radius: 12px;
    height: fit-content;
    left: 1%;
    top: 50%;
    transform: translate(0, -50%);
    // 音频 css
    .custom-content-audio {
      height: 100px;
      .close-dialog {
        position: absolute;
        right: -9px;
        top: -9px;
        width: 16px;
        height: 16px;
        box-sizing: content-box;
        padding: 2px;
        border-radius: 100%;
        background: gray;
        /* line-height: 32px; */
        color: #ffffff;
        font-size: 14px;
        cursor: pointer;
      }
      .audio-resource-name {
        box-sizing: border-box;
        height: 50%;
        line-height: 50%;
        padding-top: 25px;
        width: 70%;
        text-align: left;
        padding-left: 20px;
        font-size: 16px;
        color: #d3d1d1;
      }
      /deep/ .prism-info-display {
        display: none;
        width: 100%;
        color: #fff;
        font-size: 18px;
        text-align: center;
        top: 50%;
        position: absolute;
        z-index: 900;
        height: 44px;
        line-height: 28px;
        font-size: 16px;
        padding: 10px 12px 10px 12px;
      }
      /deep/ .prism-player {
        height: 50% !important;
        background-color: transparent;
        position: absolute;
        bottom: 0 !important;
        top: auto !important;
      }
      /deep/ .prism-controlbar {
        display: block !important;
        width: 100%;
        height: 44px;
        position: relative;
        z-index: 10;
        font-size: 14px;
      }
      /deep/ .prism-big-play-btn {
        display: none !important;
        z-index: 1000;
      }
    }
  }
}
</style>
