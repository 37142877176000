<template>
  <el-dialog
    class="compare-lession"
    width="720px"
    :visible="dialogVisible"
    :title="$t('el.classroomWps.selectResourcesCompare')"
    custom-class="compare-custom-class"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="closeDialog"
  >
    <div
      ref="fileListBox"
      class="menu-file-list"
      @mousedown.stop
      v-loading="fileListLoading"
    >
      <el-tabs
        @tab-click="changeResourceTab"
        class="tab_manage_menu"
        v-model="activeResourceName"
      >
        <el-tab-pane
          v-for="item in resourceTabList"
          :key="item.name"
          :name="item.name"
          :label="$t(item.label)"
        ></el-tab-pane>
      </el-tabs>
      <div class="file-list-box">
        <div class="file-type-title">
          {{ $t("el.classroomWps.groupResource") }}
        </div>
        <div v-if="fileData.standardList.length === 0" class="no-file">
          {{ $t("el.common.noResources") }}
        </div>
        <div
          v-for="item in fileData.standardList"
          :key="item.resourceId"
          class="file-item"
          :class="
            resourceId == item.resourceId ||
            compareResourceId == item.resourceId ||
            getResourceType(item.format) === 'otherType'
              ? 'file-item-disabled'
              : ''
          "
          @click.stop="
            resourceId == item.resourceId ||
            compareResourceId == item.resourceId
              ? ''
              : changeFilePage(item)
          "
        >
          <img :src="item.resourceUrl | getFileType" alt="" class="file-icon" />
          <div class="flex flex-m" style="width: 100%">
            <p
              :class="
                resourceId == item.resourceId ? 'file-name-active' : 'file-name'
              "
              :style="
                resourceId == item.resourceId
                  ? 'width: 450px;'
                  : 'width: 540px;' + 'text-align: left;'
              "
              slot="reference"
            >
              <tooltip-over
                :content="item.resourceName"
                :singleAble="true"
              ></tooltip-over>
            </p>
            <span
              v-if="resourceId == item.resourceId"
              class="current-resource"
              >{{ $t("el.classroomWps.currentResource") }}</span
            >
            <el-image
              v-if="compareResourceId == item.resourceId"
              class="icon-checked"
              :src="iconChecked"
            ></el-image>
          </div>
        </div>
        <!-- 学校资源 -->
        <div class="file-type-title">
          {{ $t("el.classroomWps.schoolResource") }}
        </div>
        <div v-if="fileData.collectiveList.length === 0" class="no-file">
          {{ $t("el.common.noResources") }}
        </div>
        <div
          v-for="item in fileData.collectiveList"
          :key="item.resourceId"
          class="file-item"
          :class="
            resourceId == item.resourceId ||
            compareResourceId == item.resourceId ||
            getResourceType(item.format) === 'otherType'
              ? 'file-item-disabled'
              : ''
          "
          @click.stop="
            resourceId == item.resourceId ||
            compareResourceId == item.resourceId
              ? ''
              : changeFilePage(item)
          "
        >
          <img :src="item.resourceUrl | getFileType" alt="" class="file-icon" />
          <div class="flex flex-m" style="width: 100%">
            <p
              :class="
                resourceId == item.resourceId ? 'file-name-active' : 'file-name'
              "
              :style="
                resourceId == item.resourceId
                  ? 'width: 450px;'
                  : 'width: 540px;' + 'text-align: left;'
              "
              slot="reference"
            >
              <tooltip-over
                :content="item.resourceName"
                :singleAble="true"
              ></tooltip-over>
            </p>
            <span
              v-if="resourceId == item.resourceId"
              class="current-resource"
              >{{ $t("el.classroomWps.currentResource") }}</span
            >
            <el-image
              v-if="compareResourceId == item.resourceId"
              class="icon-checked"
              :src="iconChecked"
            ></el-image>
          </div>
        </div>
        <div class="file-type-title">
          {{ $t("el.classroomWps.myResourse") }}
        </div>
        <div v-if="fileData.personalList.length === 0" class="no-file">
          {{ $t("el.common.noResources") }}
        </div>
        <div
          v-for="item in fileData.personalList"
          :key="item.resourceId"
          class="file-item"
          :class="
            resourceId == item.resourceId ||
            compareResourceId == item.resourceId ||
            getResourceType(item.format) === 'otherType'
              ? 'file-item-disabled'
              : ''
          "
          @click.stop="
            resourceId == item.resourceId ||
            compareResourceId == item.resourceId
              ? ''
              : changeFilePage(item)
          "
        >
          <img :src="item.resourceUrl | getFileType" alt="" class="file-icon" />

          <div class="flex flex-m" style="width: 100%">
            <p
              :class="
                resourceId == item.resourceId ? 'file-name-active' : 'file-name'
              "
              :style="
                resourceId == item.resourceId
                  ? 'width: 450px;'
                  : 'width: 540px;' + 'text-align: left;'
              "
              slot="reference"
            >
              <tooltip-over
                :content="item.resourceName"
                :singleAble="true"
              ></tooltip-over>
            </p>
            <span
              v-if="resourceId == item.resourceId"
              class="current-resource"
              >{{ $t("el.classroomWps.currentResource") }}</span
            >
            <el-image
              v-if="compareResourceId == item.resourceId"
              class="icon-checked"
              :src="iconChecked"
            ></el-image>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { queryResource } from "@/api/classroom";
import { getObsUrl } from "@/api/upload.js";
import tooltipOver from "@/components/biz-components/tooltipOver/index.vue";
export default {
  data() {
    return {
      fileData: { standardList: [], collectiveList: [], personalList: [] },
      fileListLoading: false, // 文件列表的loading
      activeResourceName: "KJ",
      dialogVisible: false,
      iconChecked: require("@/assets/images/icon/icon-checked.png"),
    };
  },
  filters: {
    getFileType(resourceUrl) {
      let ppt = require("@/assets/images/icon/pic_format_ppt@2x.png");
      let doc = require("@/assets/images/icon/pic_format_word@2x.png");
      let excel = require("@/assets/images/icon/excel.png");
      let pdf = require("@/assets/images/icon/pdf.png");
      let mp4 = require("@/assets/images/icon/pic_format_mp4@2x.png");
      let otherfile = require("@/assets/images/icon/other-file.png");
      let type =
        resourceUrl.indexOf("pptx") > -1 ||
        resourceUrl.indexOf("ppt") > -1 ||
        resourceUrl.indexOf("PPTX") > -1 ||
        resourceUrl.indexOf("PPT") > -1
          ? ppt
          : resourceUrl.indexOf("doc") > -1 ||
            resourceUrl.indexOf("docx") > -1 ||
            resourceUrl.indexOf("DOC") > -1 ||
            resourceUrl.indexOf("DOCX") > -1
          ? doc
          : resourceUrl.indexOf("mp4") > -1 ||
            resourceUrl.indexOf("MP4") > -1 ||
            resourceUrl.indexOf("mov") > -1 ||
            resourceUrl.indexOf("MOV") > -1
          ? mp4
          : resourceUrl.indexOf("pdf") > -1 || resourceUrl.indexOf("PDF") > -1
          ? pdf
          : resourceUrl.indexOf("xls") > -1 ||
            resourceUrl.indexOf("xlsx") > -1 ||
            resourceUrl.indexOf("XLS") > -1 ||
            resourceUrl.indexOf("XLSX") > -1
          ? excel
          : otherfile;
      return type;
    },
  },
  props: {
    showCompareDialog: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: "",
    },
    resourceId: {
      type: String,
      default: "",
    },
    compareResourceId: {
      type: String,
      default: "",
    },
  },
  created() {
    // this.toQueryResource(this.activeResourceName || "KJ");
  },
  components: {
    tooltipOver,
  },
  watch: {
    showCompareDialog(newVal, oldVal) {
      this.dialogVisible = newVal;
      if (newVal) {
        this.activeResourceName = "KJ";
        this.toQueryResource(this.activeResourceName || "KJ");
      }
    },
  },
  computed: {
    resourceTabList() {
      return [
        {
          name: "KJ",
          label: this.$t("el.classroomWps.courseware"),
        },
        {
          name: "JA",
          label: this.$t("el.classroomWps.lessonPlan"),
        },
        {
          name: "KTZL",
          label: this.$t("el.classroomWps.classmaterials"),
        },
        {
          name: "BKSP",
          label: this.$t("el.classroomWps.lessonVideo"),
        },
        {
          name: "ZY",
          label: this.$t("el.classroomWps.worksheet"),
        },
        {
          name: "KQYX",
          label: this.$t("el.classroomWps.homework"),
        },
      ];
    },
  },
  methods: {
    // change tab
    changeResourceTab(tab) {
      console.log("tab=======", tab);
      let tabName = tab.name;
      this.toQueryResource(tabName);
    },
    toQueryResource(name) {
      this.fileListLoading = true;
      return queryResource(name, {
        knowledgeId: this.id,
        originTenantId: this.$route.query.originTenantId || "",
      })
        .then((res) => {
          this.fileData = res.data;
          this.fileListLoading = false;
        })
        .catch(() => {
          this.fileListLoading = false;
        });
    },
    // 关闭弹窗
    closeDialog() {
      this.$emit("close-dialog", false);
    },
    async changeFilePage(item) {
      console.log("resourceitem======", item);
      // 重置 预览 显示
      this.showPreviewDialog = false;
      this.previewShow = false;
      this.$nextTick(async () => {
        this.currentFile = item;
        // this.resourceId = item.resourceId;
        let obj = {
          resource: item,
          resourceId: item.resourceId,
          classId: this.$route.query.classId,
          id: this.$route.query.classId,
          format: item.format,
          version: item.version,
          gradeId: this.$route.query.gradeId,
          classType: this.$route.query.classType,
          className: this.$route.query.className,
          schoolCode: this.$route.query.schoolCode,
          resourceName: item.resourceName,
        };
        console.log("非课件=============");
        this.fileType = this.getResourceType(item.format);
        if (["video", "audio", "image"].indexOf(this.fileType) > -1) {
          try {
            let a = await this.getPlayUrl(item.resourceUrl, item.resourceUrl);
            console.log("a=====", a);
            this.playUrl = a;
            obj.playUrl = this.playUrl;
            this.$emit("selectBack", obj, this.fileType);

            // if (this.fileType === "image") {
            //   this.previewShow = true;
            //   this.previewImg = a;
            //   this.$emit("selectBack", obj, this.fileType);
            // } else {
            //   //   this.showPreviewDialog = true;
            //   this.$emit("selectBack", obj, this.fileType);
            // }
          } catch (error) {
            console.log("error====", error);
          }
        } else if (this.fileType === "wpsFile") {
          //

          //   this.$emit("changewpscurryPage", 1, 1);
          // this.$parent.selectBack(obj,1)
          this.$emit("selectBack", obj, this.fileType);
        } else {
          //
          this.$message.info(this.$t("el.classroomWps.notSupportPreview"));
        }
      });
    },
    // 获取播放链接
    getPlayUrl(objectName, displayName) {
      let params = {
        objectName,
        displayName,
        isDownload: 0,
      };
      return getObsUrl(params).then((result) => {
        console.log("result==", result);
        if (typeof result.data === "string") {
          return result.data;
        } else {
          if (result.data && result.data.downloadUrl) {
            result.data.downloadUrl.indexOf("http://") > -1 &&
              (result.data.downloadUrl = result.data.downloadUrl.replace(
                "http://",
                "//"
              ));
            result.data.downloadUrl.indexOf("https://") > -1 &&
              (result.data.downloadUrl = result.data.downloadUrl.replace(
                "https://",
                "//"
              ));
          }

          return (result.data && result.data.downloadUrl) || "";
        }
      });
    },
    // 获取资源类型
    getResourceType(format) {
      if (["mp4", "MP4", "mov", "MOV"].indexOf(format) > -1) {
        return "video";
      } else if (
        [
          "mp3",
          "MP3",
          "wav",
          "WAV",
          "flac",
          "FLAC",
          "aac",
          "AAC",
          "m4a",
          "M4A",
        ].indexOf(format) > -1
      ) {
        return "audio";
      } else if (
        [
          "doc",
          "docx",
          "DOC",
          "DOCX",
          "ppt",
          "pptx",
          "PPT",
          "PPTX",
          "pdf",
          "PDF",
          "xls",
          "xlsx",
          "xlsx",
          "XLSX",
        ].indexOf(format) > -1
      ) {
        return "wpsFile";
      } else if (
        ["png", "PNG", "jpg", "JPG", "jpeg", "JPEG"].indexOf(format) > -1
      ) {
        return "image";
      } else {
        return "otherType";
      }
    },
  },
};
</script>

<style lang="less" scoped>
.compare-lession {
  .menu-file-list {
    // width: 350px;
    height: 482px;
    box-sizing: border-box;
    background-color: #fff;
    overflow-y: auto;
    // padding:10px 0;
    /deep/ .el-tabs__nav-prev {
      left: 0;
      height: 36px;
      line-height: 36px;
    }
    /deep/ .el-tabs__nav-next {
      right: 0;
      height: 36px;
      line-height: 36px;
    }
    /deep/ .el-tabs__item {
      padding: 0 25px;

      -webkit-box-sizing: border-box;
      box-sizing: border-box;

      list-style: none;
      position: relative;

      height: 40px;
      font-size: 14px;
      // font-family: PingFang-SC-Medium, PingFang-SC;
      font-weight: 400;
      color: #131313;
    }
    /deep/ .el-tabs__active-bar {
      position: absolute;
      bottom: 0;
      left: 0;
      height: 3px;
      background-color: #5a53f5;
      z-index: 1;
      transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      list-style: none;
    }
    /deep/ .el-tabs__header {
      padding: 0;
      position: relative;
      margin: 0 0 0px;
    }
    .file-type-title {
      padding: 2px 14px;

      text-align: left;

      font-size: 14px;
      // font-family: PingFang-SC-Medium, PingFang-SC;
      // font-weight: 500;
      color: #666666;
      line-height: 28px;
    }
    .no-file {
      color: rgba(100, 108, 149, 0.55);
      font-size: 14px;
    }
    .file-item {
      display: flex;
      padding: 4px 14px;
      cursor: pointer;
      align-items: center;
      &:hover {
        background-color: #f2f2f2;
      }
      .file-icon {
        width: 24px;
        height: 24px;
        margin-right: 6px;
      }
      .file-name {
        word-break: break-word;
        word-wrap: break-word;
        font-size: 14px;
        // font-family: PingFang-SC-Medium, PingFang-SC;
        // font-weight: 500;
        color: #666666;
        line-height: 28px;
      }
      .file-name-active {
        font-size: 14px;
        color: #6049ff;
        word-break: break-word;
        word-wrap: break-word;
        // font-family: PingFang-SC-Medium, PingFang-SC;
        // font-weight: 500;

        line-height: 28px;
        text-align: left;
      }
      .current-resource {
        width: fit-content;
        padding: 4px 12px;
        border-radius: 12px;
        border: 1px solid #5a53f5;
        margin-left: auto;
        margin-right: 10px;
        font-size: 12px;
        // font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #5a53f5;
      }
      .icon-checked {
        width: 24px;
        height: 24px;
        margin-left: auto;
        margin-right: 10px;
      }
    }
    .file-item-disabled {
      display: flex;
      padding: 4px 14px;
      cursor: not-allowed;
      align-items: center;
      &:hover {
        background-color: #f2f2f2;
      }
    }
  }
  .menu-file-list::-webkit-scrollbar {
    display: none;
  }
  .file-list-box {
    padding: 10px 0;
    -webkit-box-shadow: 2px 5px 5px 0 rgb(85 84 103 / 30%);
    box-shadow: 2px 5px 5px 0 rgb(85 84 103 / 30%);
    height: 420px;
    box-sizing: border-box;
    overflow: auto;
  }
  .toolicon {
    width: 36px;
    height: 36px;
  }
  .menu-nav {
    width: 22px;
    height: 96px;
    background: #f6f7f9;
    border-radius: 5px 0 0 5px;
    position: absolute;
    top: calc(50% - 48px);
    left: -21px;
    cursor: pointer;
    i {
      width: 10px;
      vertical-align: middle;
      margin-top: 28px;
      margin-right: 0;
      margin-left: 6px;
    }
  }
  .menu-content {
    width: 88px;
    background: #f8f8f8;
    // border-radius: 10px;
    color: #333;
    padding: 18px 0 1px 0;
    box-shadow: 2px 5px 5px 0 rgba(85, 84, 103, 0.3);
    box-sizing: border-box;
    font-size: 14px;
    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      line-height: 26px;
      margin-bottom: 20px;
      cursor: pointer;
    }
  }
}
/deep/ .compare-custom-class {
  border-radius: 10px;
  .el-dialog__header {
    padding: 16px 20px 0 !important;

    // font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #131313;
    text-align: left;
  }
  .el-dialog__title {
    line-height: 24px;
    font-size: 14px;
    color: #303133;
  }
  .el-dialog__body {
    padding: 0 20px;
    color: #606266;
    font-size: 14px;
    word-break: break-all;
  }
}
</style>
