import axios from "@/plugins/axios.js";

/**
 * 获取班级学生列表
 * @param {*} params 查询参数
 */
export function getClassStudentList(params) {
  return axios.get("/classStu/studentListByClassId", {
    params,
  });
}

/**
 * 获取班级学生列表2
 * @param {*} params 查询参数
 */
export function getClassStudentList2(params) {
  return axios.get("/courseClass/getStudentInClassId", {
    params,
  });
}
/**
 * 数据埋点          开始授课
 * @param {*} data
 */
export function getStartLesson(data) {
  return axios.post("/teachManage/startLesson", data, {
    baseURL: "/aiykt-gateway/data-point",
  });
}

/**
 * 数据埋点          保持授课
 * @param {*} data
 */
export function getKeepLesson(params) {
  return axios.get("/teachManage/keepLesson", {
    params,
    baseURL: "/aiykt-gateway/data-point",
  });
}

/**
 * 数据埋点          手动结束授课
 * @param {*} data
 */
export function delPersonResource(params) {
  return axios.post("/teachManage/finishLesson", params, {
    params,
    baseURL: "/aiykt-gateway/data-point",
  });
}

// 获取点名的学生列表
export function getRollCallStudent(importCourseId, classId) {
  return axios.get(
    `/courseClass/studentInfoByClassId/${importCourseId}/${classId}`
  );
}

// 学生点名
export function rollCall(data) {
  return axios.post("/student/attendance/attendance/roll/call", data);
}

// 获取跟读的单词数据
export function getWordsList(id) {
  return axios.get(`/knowledgeResource/listWordRange/${id}`);
}
