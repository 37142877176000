<template>
  <el-dialog
    :visible.sync="visible"
    width="770px"
    center
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancel"
  >
    <div class="select">
      <el-scrollbar class="select-l el-scrollbar_x">
        <div class="head">{{ $t("el.teaching.selectCourse") }}：</div>
        <div
          class="item"
          :class="[index == courseNum ? 'active' : '']"
          v-for="(item, index) in resourcesList"
          :key="index"
          @click="chooseCourse(item, index)"
        >
          <div class="section">
            <span class="mine" v-if="item.isOwner == 1">{{
              $t("el.teaching.mine")
            }}</span>
            {{ item.resourceName }}
          </div>
        </div>
      </el-scrollbar>
      <el-scrollbar v-if="!check(['third'])" class="select-r el-scrollbar_x">
        <div class="head">
          {{ $t("el.teaching.selectClass") }}：
          <span>{{ $t("el.teaching.selectClassTips") }}</span>
        </div>
        <div class="no-data" v-if="!classList.length && classDeleted">
          {{ $t("el.teaching.noClassOrDelete") }}
        </div>
        <div class="no-data" v-else-if="!classList.length">
          {{ $t("el.teaching.noClass")
          }}{{ !$store.state.user.isHiddenSign ? "，" : "" }}
          <span
            v-if="!$store.state.user.isHiddenSign"
            class="add-class"
            @click="addClass"
            >{{ $t("el.teaching.add") }}</span
          >
        </div>
        <div
          class="item"
          :class="[index == classNum ? 'active' : '']"
          v-for="(item, index) in classList"
          :key="index"
          @click="chooseClass(item, index)"
        >
          <div class="section">{{ item.className }}</div>
        </div>
      </el-scrollbar>
    </div>
    <div class="footer">
      <el-button class="submit" type="primary" @click="submit">{{
        $t("el.common.confirm")
      }}</el-button>
      <el-button class="cancel" @click="cancel">{{
        $t("el.common.cancel")
      }}</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { getQueryResourceAndClass } from "@/api/classroom/bk-kj";
import { addDownloadLikeRecord } from "@/api/classroom/index.js";
import { openWindow } from "@/utils";
import { check } from "../utils/auth";
import { getHidePrepareMenu } from "@/core/auth";

/**
 * 两个值字符串是否相等
 * @param {*} str1
 * @param {*} str2
 */
function eqString(str1, str2) {
  return str1 + "" === str2 + "";
}
export default {
  props: {
    /**
     * 是否全屏状态
     */
    fullscreen: Boolean,
    courseId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      loading: false,
      visible: false,
      isHidePrepareMenu: false,
      courseNum: null,
      classNum: null,
      resourcesList: [],
      classList: [],
      classDeleted: false, // 班级是否被删除
      params: {
        class: null,
        resource: null,
        resourceId: "",
        classId: "",
        format: "",
        version: "",
      },
    };
  },
  watch: {
    classList() {
      return this.$emit("change-classlist", this.classList);
    },
  },
  computed: {},
  created() {
    this.isHidePrepareMenu =
      getHidePrepareMenu() === "true" || getHidePrepareMenu() === true;
  },
  methods: {
    check,
    addClass() {
      // this.$router.push({
      //   name: "classManage"
      // });
      // 打开新窗口
      openWindow("manage/classManage");
    },
    chooseCourse(item, index) {
      this.courseNum = index;
      this.params.resourceId = item.resourceId;
      this.params.resource = item;
      this.params.format = item.format;
      this.params.version = item.version;
    },
    chooseClass(item, index) {
      this.classNum = index;
      this.params.classId = item.id;
      this.params.class = item;
    },
    /**
     * 获取数据
     * @param {*} knowledgeId 知识点id
     * @param {*} resourceId 资源id
     * @param {*} classId 选中的班级id
     */
    getData(knowledgeId, resourceId, classId, resourceType) {
      var loading = this.$loading({
        text: this.$t("el.teaching.loadingData"),
      });
      getQueryResourceAndClass(resourceType, { knowledgeId })
        .then(({ data }) => {
          // console.log(data);
          this.classList = Array.isArray(data.classes) ? data.classes : [];
          if (this.courseId) {
            let item = this.classList.find(
              (item) => ~~this.courseId === item.courseId
            );
            if (item) {
              this.classDeleted = false;
              this.classList = [item];
            } else {
              this.classDeleted = true;
              this.classList = [];
            }
          }
          this.resourcesList = Array.isArray(data.resources)
            ? data.resources
            : [];

          // 查找资源是否存在
          var resourceIndex = -1;
          if (this.resourcesList.length && resourceId)
            resourceIndex = this.resourcesList.findIndex((item) =>
              eqString(item.resourceId, resourceId)
            );
          var resource = this.resourcesList[resourceIndex];

          // 已选择资源，继续选择班级
          if (
            this.params.resource &&
            eqString(this.params.resource.resourceId, resourceId)
          ) {
            this.visible = true;
            return;
          }

          // 查找班级是否存在
          var classIndex = -1;
          if (this.classList.length && classId)
            classIndex = this.classList.findIndex((item) =>
              eqString(item.id, classId)
            );
          var classItem = this.classList[classIndex];
          var res = 0;
          if (this.classList.length <= 1) {
            // 班级小于等于1
            if (this.classList.length) {
              // 如果只有一个班级自动选中第一个班级
              this.chooseClass(this.classList[0], 0);
            }
            res++;
          } else if (classIndex !== -1) {
            // 是否有选中的班级
            this.chooseClass(classItem, classIndex);
            res++;
          }
          if (resourceIndex !== -1) {
            // 是否有选中的课件
            this.chooseCourse(resource, resourceIndex);
            res++;
          } else if (this.resourcesList.length === 1) {
            // 无默认选中课件，并且只有一个课件自动选中第一个课件
            this.chooseCourse(this.resourcesList[0], 0);
            res++;
          }
          if (res === 2) return this.$emit("select-back", this.params);
          if (this.params.resourceId && this.isHidePrepareMenu) {
            this.submit();
          } else {
            this.visible = true;
          }
        })
        .finally(() => {
          loading.close();
        });
    },
    /**
     * 初始化显示
     * @param {*} knowledgeId 知识点id
     * @param {*} resourceId 资源id
     * @param {*} classId 选中的班级id
     */
    init(knowledgeId, resourceId, classId, resourceType) {
      this.getData(knowledgeId, resourceId, classId, resourceType);
    },
    submit() {
      if (!this.params.resourceId) {
        this.$message.warning(this.$t("el.teaching.chooseCourseWare"));
        return;
      }
      // if (!this.params.classId) {
      //   this.$message.warning("请选择班级");
      //   return;
      // }
      // addDownloadLikeRecord(this.params.resourceId, 2).then((data)=>{
      this.$emit("select-back", this.params);
      // }).catch(()=>{
      //   this.$emit("select-back", this.params);
      // })
      this.visible = false;
    },
    cancel() {
      if (this.fullscreen) {
        this.$emit("onFullscreen");
      }
      this.$emit("cancel");
      this.visible = false;
    },
  },
};
</script>
<style lang="less" scoped>
.select {
  display: flex;
  // overflow: auto;
  overflow: hidden;
  .select-l {
    padding-left: 22px;
    height: 500px;
    // overflow: auto;
    flex: 1;
    border-right: 1px solid #ececf0;
    .head {
      padding-top: 24px;
      font-size: 16px;
      color: rgba(100, 108, 149, 1);
      line-height: 21px;
      margin-bottom: 25px;
    }
    .item {
      float: left;
      width: 136px;
      min-height: 64px;
      padding: 12px 8px;
      background: rgba(249, 249, 249, 1);
      border-radius: 4px;
      margin-bottom: 18px;
      line-height: 23px;
      height: 72px;
      .section {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        padding-bottom: 1px;
        .mine {
          background: rgba(255, 255, 255, 1);
          border-radius: 3px;
          border: 1px solid rgba(73, 124, 240, 1);
          padding: 1px 3px;
          font-size: 10px;
          color: rgba(73, 124, 240, 1);
          line-height: 14px;
        }
      }
    }
    .item:nth-child(n) {
      margin-right: 22px;
    }
  }
  .select-r {
    flex: 1;
    padding-left: 22px;
    height: 500px;
    // overflow: auto;
    .head {
      font-size: 16px;
      color: rgba(100, 108, 149, 1);
      line-height: 21px;
      margin-bottom: 25px;
      padding-top: 24px;
      line-height: 23px;
      span {
        font-size: 14px;
        color: rgba(176, 176, 176, 1);
        line-height: 19px;
      }
    }
    .item {
      float: left;
      width: 136px;
      min-height: 40px;
      padding: 12px 8px;
      background: rgba(249, 249, 249, 1);
      border-radius: 4px;
      margin-bottom: 16px;
      .section {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
      }
    }
    .item:nth-child(n) {
      margin-right: 22px;
    }
    .no-data {
      font-size: 16px;
      color: rgba(100, 108, 149, 1);
      line-height: 37px;
      letter-spacing: 1px;
      padding-top: 158px;
      text-align: center;
      .add-class {
        font-size: 16px;
        color: rgba(73, 124, 240, 1);
        line-height: 37px;
        letter-spacing: 1px;
        text-decoration: underline;
      }
    }
  }
}
.footer {
  background: rgba(250, 250, 254, 1);
  border-radius: 0px 0px 6px 6px;
  padding: 12px 0;
  margin: 0 auto;
  text-align: center;
  .submit {
    margin-right: 18px;
  }
  .cancel {
    margin-left: 18px;
  }
}
.en-status {
  .select .select-r .head span {
    display: inline-block;
    width: 100%;
  }
}
.active {
  border: 1px solid rgba(73, 124, 240, 1);
  padding: 11px 7px !important;
}
/deep/ .el-dialog__body {
  padding: 0 0 0 0 !important;
}
/deep/ .el-dialog__header {
  padding: 0 0 0 0 !important;
}
/deep/ .el-dialog__headerbtn {
  z-index: 10;
}
</style>
