<template>
  <!-- 编辑状态wps -->
  <div id="wpsFile"></div>
</template>
<script>
import Vue from "vue";
import {
  setWordListener,
  setPPTListener,
  setPDFListener,
  setXLSListener,
  removeWordListener,
  removePPTListener,
  removeXLSListener,
  removePDFListener,
} from "../../utils/wps-listener";
import { mapActions } from "vuex";
import { getToken } from "@/core/auth";
export default {
  props: {
    // eslint-disable-next-line vue/require-prop-type-constructor
    wpsUrl: "",
    // eslint-disable-next-line vue/require-prop-type-constructor
    token: "",
    isShowTopArea: {
      type: Boolean,
      default: true,
    },
    // eslint-disable-next-line vue/require-prop-type-constructor
    type: "",
    /**
     * 课件对象
     */
    courseware: {
      type: Object,
      default: () => {},
    },
    wpsParams: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      teststate: true,
      simpleMode: false, // 是否开启简易模式
      wpscurryPage: 1, // 当前页码
      officeType: "",
      jssdk: null,
    };
  },
  mounted() {
    this.openWps(this.wpsParams, getToken());
  },
  watch: {
    wpsParams(v) {
      this.openWps(v, getToken());
    },
  },
  beforeDestroy() {
    console.log("beforeDestroy=============");
    this.removeListiner(this.jssdk);
  },
  methods: {
    ...mapActions(["setPageAction"]),
    /**
     * wps初始化
     */
    async openWps(wpsParams, token) {
      let _this = this;
      this.jssdk = _this.wps.init({
        officeType: wpsParams.w_file_format,
        mode: _this.simpleMode ? "simple" : "normal",
        mount: document.querySelector("#editwpsBox"),
        appId: wpsParams.w_app_id,
        fileId: wpsParams.w_file_id,
        token,
        customArgs: wpsParams,
        pptOptions: {
          isInSafeMode: true, // 安全模式
          isShowRemarkView: false, // 是否显示备注视图
        },
        commonOptions: {
          isShowTopArea: _this.isShowTopArea, // 隐藏顶部区域(头部和工具栏)
          isBrowserViewFullscreen: true, // 是否在浏览器区域全屏 true禁止全屏
          isIframeViewFullscreen: true, // 是否在 iframe 区域内全屏
        },
        commandBars: [
          {
            cmbId: "MoreMenus", // 组件 控制更多菜单隐藏
            attributes: {
              visible: false, // 隐藏组件
            },
          },
          {
            cmbId: "FloatQuickHelp", // 组件 页面定制对象：右下角帮助（金小獴）
            attributes: {
              visible: false, // 隐藏组件
            },
          },
          {
            cmbId: "TabPrintPreview", // 组件 页面定制对象：打印
            attributes: {
              visible: false, // 隐藏组件
            },
          },
          {
            cmbId: "HeaderHistoryMenuBtn", // 组件 顶部状态栏-历史记录菜单按钮
            attributes: {
              visible: false, // 隐藏组件
            },
          },
          {
            cmbId: "WPPPcCommentButton", // 组件 演示-底部工具栏-评论按钮
            attributes: {
              visible: false, // 隐藏组件
            },
          },
          {
            cmbId: "TabReviewTab", // 组件 演示-工具栏-审阅按钮
            attributes: {
              visible: false, // 隐藏组件
            },
          },
          {
            cmbId: "TabReviewWord", // 组件 word工具栏-审阅按钮
            attributes: {
              visible: false, // 隐藏组件
            },
          },
          {
            cmbId: "ContextMenuConvene", // 组件 word-右键-召唤在线协助者
            attributes: {
              visible: false, // 隐藏组件
            },
          },
          {
            cmbId: "BookMarkContextMenu", // 组件 文字-右键-插入书签
            attributes: {
              visible: false, // 隐藏组件
            },
          },
          {
            cmbId: "PlayComponentToolbar", // 组件 全屏播放时 Toolbar
            attributes: {
              visible: false, // 隐藏组件
            },
          },
        ],
      });
      this.jssdk.on("fileOpen", (data) => {
        console.log("文件打开", data);
        if (!data.success) {
          console.log("文件打开失败");
        } else {
          this.officeType = data.fileInfo.officeType || "";
        }
      });
      this.jssdk.setToken({ token });
      Vue.prototype.wpsConfig = this.jssdk; // 全局定义wpsConfig，通过this.wpsConfig调用改变wps
      let app = this.jssdk.Application;
      // if (_this.type === 1) {
      // type=1 word
      // _this.setwordwps(wps);
      // return
      // }
      await this.jssdk.ready();
      console.log("_this.officeType", this.officeType);
      console.log("wps", this.jssdk);
      switch (this.officeType) {
        case "w":
          setWordListener(this.jssdk, this.handleActionCallback);
          break;
        case "p":
          console.log("p");
          setPPTListener(this.jssdk, this.handleActionCallback);
          break;
        case "s":
          setXLSListener(this.jssdk, this.handleActionCallback);
          break;
        case "f":
          setPDFListener(this.jssdk, this.handleActionCallback);
          break;

        default:
          setWordListener(this.jssdk, this.handleActionCallback);
          break;
      }
      if (_this.type === 2) {
        // type=2 ppt
        _this.setwps(this.jssdk);
      }
    },

    handleActionCallback() {
      console.log("handleActionCallback");
      this.setPageAction(this.courseware);
    },

    removeListiner(wps) {
      switch (this.officeType) {
        case "w":
          removeWordListener(wps, this.handleActionCallback);
          break;
        case "p":
          removePPTListener(wps, this.handleActionCallback);
          break;
        case "s":
          removeXLSListener(wps, this.handleActionCallback);
          break;
        case "f":
          removePDFListener(wps, this.handleActionCallback);
          break;

        default:
          removeWordListener(wps, this.handleActionCallback);
          break;
      }
    },

    /**
     * ppt格式设置
     */
    async setwps(wps) {
      let _this = this;
      // await wps.ready();
      const app = wps.Application;

      // eslint-disable-next-line require-atomic-updates
      app.Sub.SlideShowBegin = async () => {
        console.log("进入");
        const SlideShowWindow = await app.ActivePresentation.SlideShowWindow;
        // 视图对象
        const view = await SlideShowWindow.View;
        view.ShowPage = true; // 【播放模式】显示页码
        const menu = await app.Enum.PpToolType.pcPlayingMenu; // 右键菜单
        view.SetToolVisible(menu, false);
      };
    },
    /**
     * Word格式设置
     */
    async setwordwps(wps) {
      await wps.ready();
      let app = wps.Application;
      const moreMenus = await app.CommandBars("MoreMenus"); // 控制更多菜单隐藏
      moreMenus.Visible = false;
      const FloatQuickHelp = await app.CommandBars("FloatQuickHelp"); // 页面定制对象：右下角帮助（金小獴）
      FloatQuickHelp.Visible = false;
      const TabPrintPreview = await app.CommandBars("TabPrintPreview"); // 页面定制对象：打印
      TabPrintPreview.Visible = false;
      const TabReviewWord = await app.CommandBars("TabReviewWord"); // 页面定制对象：word工具栏-审阅按钮
      TabReviewWord.Visible = false;
      const ContextMenuConvene = await app.CommandBars("ContextMenuConvene"); // 页面定制对象：word-右键-召唤在线协助者
      ContextMenuConvene.Visible = false;
      const BookMarkContextMenu = await app.CommandBars("BookMarkContextMenu"); // 页面定制对象：文字-右键-插入书签
      BookMarkContextMenu.Visible = false;
      const HeaderHistoryMenuBtn = await app.CommandBars(
        "HeaderHistoryMenuBtn"
      ); // 页面定制对象：顶部状态栏-历史记录菜单按钮
      HeaderHistoryMenuBtn.Visible = false;
    },
  },
};
</script>

<style lang="less" scoped></style>
