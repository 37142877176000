var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"width":"640px","close-on-click-modal":false,"close-on-press-escape":false,"visible":_vm.visible},on:{"update:visible":function($event){_vm.visible=$event}}},[_c('div',{staticClass:"ks-header",attrs:{"slot":"title"},slot:"title"},[_c('div',{staticClass:"ks-title"},[_vm._v(_vm._s(_vm.$t("el.common.chooseKnowledge")))]),_c('div',{staticClass:"ks-subtitle"},[_vm._v(" "+_vm._s(_vm.courseInfo.courseName)+" "),(_vm.courseInfo.isTry)?_c('span',{staticClass:"details-course-try"},[_vm._v(_vm._s(_vm.$t("el.common.onTrial")))]):_vm._e()])]),_c('div',{staticClass:"ks-main"},[_c('el-scrollbar',{ref:"scroll",staticClass:"el-scrollbar_x",attrs:{"noresize":""}},[(_vm.list && _vm.list.length)?_c('div',{staticClass:"ks-node__wrap"},_vm._l((_vm.list),function(item,index){return _c('dl',{key:index,staticClass:"ks-node tb"},[_c('dt',{staticClass:"flex flex-m"},[_c('i',{staticClass:"icon-knowledge-title"}),_c('div',{staticClass:"details-chapter-title"},[_c('div',[_vm._v("U"+_vm._s(index + 1))]),_c('div',{staticClass:"u-line",staticStyle:{"margin-top":"-10px"}})]),_c('span',[_vm._v(_vm._s(item.knowledgeName))])]),(item.children && item.children.length)?_vm._l((item.children),function(node,i){return _c('dd',{key:i,staticClass:"flex flex-m",class:{
                disabled: node.id == _vm.currentId,
                selected: node.id == _vm.selectedItem.id,
              },on:{"click":function($event){!(node.id == _vm.currentId) && _vm.isSwitch && _vm.selected(node)}}},[_c('div',{staticClass:"ks-node__name t-over2",style:(!_vm.isSwitch && node.canClick
                    ? 'width: calc(100% - 125px)'
                    : 'width: 100%'),on:{"click":function($event){return _vm.prepare(node)}}},[_c('div',{staticClass:"flex flex-m"},[_c('span',[_vm._v(_vm._s(i + 1 + "、" + node.knowledgeName))]),(node.isTeacherTrain)?_c('span',{staticClass:"ks-node__train"},[_vm._v(_vm._s(_vm.$t("el.classroomWps.teacherInstructions")))]):_vm._e(),(!node.canClick)?_c('el-image',{staticStyle:{"width":"16px","height":"16px","margin-left":"auto","display":"block"},attrs:{"src":_vm.iconLock}}):_vm._e()],1)]),(!_vm.isSwitch && node.canClick)?_c('div',{staticClass:"ks-node__handle td"},[(_vm.prepareShow)?_c('span',{directives:[{name:"check-bsk-permission",rawName:"v-check-bsk-permission",value:({
                    handler: _vm.prepare,
                    handlerArgs: [node],
                    args: {
                      courseId: _vm.courseId,
                      originTenantId: _vm.originTenantId,
                    },
                  }),expression:"{\n                    handler: prepare,\n                    handlerArgs: [node],\n                    args: {\n                      courseId: courseId,\n                      originTenantId: originTenantId,\n                    },\n                  }"}],staticClass:"ks-node__btn"},[_vm._v(_vm._s(_vm.$t("el.courseDetails.prepareLessons")))]):_vm._e(),_c('span',{directives:[{name:"check-bsk-permission",rawName:"v-check-bsk-permission",value:({
                    handler: _vm.give,
                    handlerArgs: [node],
                    args: {
                      courseId: _vm.courseId,
                      originTenantId: _vm.originTenantId,
                    },
                  }),expression:"{\n                    handler: give,\n                    handlerArgs: [node],\n                    args: {\n                      courseId: courseId,\n                      originTenantId: originTenantId,\n                    },\n                  }"}],staticClass:"ks-node__btn",class:[
                    node.standardCount + node.personCount < 1
                      ? 'disabled-class'
                      : '',
                  ]},[_vm._v(_vm._s(_vm.$t("el.courseDetails.teachingLessons")))])]):_vm._e()])}):_vm._e()],2)}),0):_vm._e()])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }