<template>
  <div ref="tools" class="tools">
    <div
      class="pen"
      :class="{
        active: currentToolName === 'pen',
      }"
      @click.stop="changeTool('pen')"
      @mousedown.stop
    >
      <i class="icon-pen"></i>
    </div>
    <div
      class="rubber"
      :class="{
        active: currentToolName === 'rubber',
      }"
      @click.stop="changeTool('rubber')"
      @mousedown.stop
    >
      <i class="icon-rubber"></i>
    </div>
    <div class="palette" @mousedown.stop>
      <el-popover
        placement="top"
        width="176"
        trigger="manual"
        v-model="visible"
      >
        <div class="allselect">
          <div class="selectcolor red" @click="choosecolor(1)">
            <img
              :src="duipic"
              v-if="color == '#e8322f'"
              class="chooseimg"
              alt=""
            />
          </div>
          <div class="selectcolor green" @click="choosecolor(2)">
            <img
              :src="duipic"
              v-if="color == '#1fbb7d'"
              class="chooseimg"
              alt=""
            />
          </div>
          <div class="selectcolor blue" @click="choosecolor(3)">
            <img
              :src="duipic"
              v-if="color == '#4178f9'"
              class="chooseimg"
              alt=""
            />
          </div>
          <div class="selectcolor white" @click="choosecolor(4)">
            <img
              :src="duipic"
              v-if="color == '#ffffff'"
              class="chooseimg"
              alt=""
            />
          </div>
          <div class="selectcolor black" @click="choosecolor(5)">
            <img
              :src="duipic"
              v-if="color == '#000000'"
              class="chooseimg"
              alt=""
            />
          </div>
        </div>
        <div
          class="colorselect"
          :class="{
            red: color == '#e8322f',
            green: color == '#1fbb7d',
            blue: color == '#4178f9',
            white: color == '#ffffff',
            black: color == '#000000',
          }"
          slot="reference"
          @click="visible = !visible"
        ></div>
      </el-popover>
      <!-- <el-popover placement="top" width="176" trigger="click">
        <div class="allselect">
          <div class="selectcolor red" @click="choosecolor(1)">
            <img :src="duipic" v-if="color == '#e8322f' " class="chooseimg" alt="">
          </div>
          <div class="selectcolor green" @click="choosecolor(2)">
            <img :src="duipic" v-if="color == '#1fbb7d' " class="chooseimg" alt="">
          </div>
          <div class="selectcolor blue" @click="choosecolor(3)">
            <img :src="duipic" v-if="color == '#4178f9' " class="chooseimg" alt="">
          </div>
          <div class="selectcolor white" @click="choosecolor(4)">
            <img :src="duipic" v-if="color == '#ffffff' " class="chooseimg" alt="">
          </div>
          <div class="selectcolor black" @click="choosecolor(5)">
            <img :src="duipic" v-if="color == '#000000' " class="chooseimg" alt="">
          </div>
        </div>
        <div class="colorselect" :class="{
          red: color == '#e8322f',
          green: color == '#1fbb7d',
          blue: color == '#4178f9',
          white: color == '#ffffff',
          black: color == '#000000'
        }" slot="reference"></div>
      </el-popover> -->
    </div>
    <!-- <div class="palette" @mousedown.stop>
      <el-color-picker ref="elColorPicker" v-model="color" :append-to-body="false"></el-color-picker>
    </div> -->
    <div class="slider" @mousedown.stop>
      <el-slider
        v-model="size"
        :min="5"
        :max="20"
        :show-tooltip="displaytips"
      ></el-slider>
    </div>
    <div class="inline"></div>
    <div
      class="back"
      :class="{ 'opacity-60': !isCanBack }"
      @click.stop="handleBack"
      @mousedown.stop
    >
      <i class="icon-back"></i>
      <span>{{ $t("el.teaching.undo") }}</span>
    </div>
    <div
      class="restore"
      :class="{ 'opacity-60': !isCanRestore }"
      @click.stop="handleRestore"
      @mousedown.stop
    >
      <i class="icon-restore"></i>
      <span>{{ $t("el.teaching.recovery") }}</span>
    </div>
    <div class="clear" @click.stop="handleClear" @mousedown.stop>
      <i class="icon-clear"></i>
      <span>{{ $t("el.teaching.clearPage") }}</span>
    </div>
    <div class="inline"></div>
    <div class="switch" @click.stop="$emit('switch-status')" @mousedown.stop>
      <span>{{ statusText }}</span>
    </div>
  </div>
</template>

<script>
import Whiteboard from "whiteboard";
import duipic from "@/assets/images/icon/icon-dui.png";
export default {
  name: "whiteboard-tools",
  props: {
    /**
     * 绘制状态
     */
    status: {
      type: Boolean,
      default: false,
    },
    number: {
      type: Number,
    }, // 当前页码
  },
  data() {
    return {
      duipic: duipic,
      w: null, // 画板对象
      size: 5, // 尺寸
      color: "#e8322f", // 颜色
      currentTool: null, // 当前使用的工具
      currentToolName: null, // 工具名称
      pointer: 0, // 指针
      count: 1, // 记录数
      visible: false,
      displaytips: true,
    };
  },
  computed: {
    /**
     * 是否可以撤回
     */
    isCanBack() {
      return this.count > 1 && this.pointer < this.count - 1;
    },
    /**
     * 是否可以恢复
     */
    isCanRestore() {
      return this.count > 1 && this.pointer > 0;
    },
    /**
     * 绘制状态文本
     */
    statusText() {
      return this.status
        ? this.$t("el.teaching.endDraw")
        : this.$t("el.teaching.startDraw");
    },
  },
  watch: {
    /**
     *颜色选择框
     */
    visible() {
      if (this.visible) {
        setTimeout(() => {
          this.visible = false;
        }, 3000);
      }
    },
    /**
     * 尺寸
     */
    size() {
      if (!this.w) return;
      this.w.setSize(this.size);
    },
    /**
     * 颜色
     */
    color() {
      if (!this.w) return;
      this.w.setColor(this.color);
    },
  },
  mounted() {},
  destroyed() {
    if (this.w) {
      this.w.$off("change-tool", this.onChangeTool);
      this.w.$off("change-history", this.onChangeHistory);
    }
  },
  methods: {
    /**
     * 选择颜色
     */
    choosecolor(type) {
      this.visible = false;
      if (type === 1) {
        this.color = "#e8322f";
      }
      if (type === 2) {
        this.color = "#1fbb7d";
      }
      if (type === 3) {
        this.color = "#4178f9";
      }
      if (type === 4) {
        this.color = "#ffffff";
      }
      if (type === 5) {
        this.color = "#000000";
      }
    },
    init(w) {
      // 空校验
      if (!(w instanceof Whiteboard)) return;
      this.w = w;
      this.w.setSize(this.size);
      this.w.setColor(this.color);
      this.w.$on("change-tool", this.onChangeTool);
      this.w.$on("change-history", this.onChangeHistory);
      this.pointer = w.pointer;
      this.count = w.store.length;
    },
    /**
     * 切换工具
     */
    changeTool(toolsName) {
      // 还未初始化
      if (!this.w) return;
      this.w.changeTool(toolsName);
    },
    /**
     * 工具切换事件
     */
    onChangeTool(tool) {
      this.currentTool = tool;
      this.currentToolName = tool.name;
    },
    /**
     * 改变历史记录
     */
    onChangeHistory(pointer, count) {
      this.pointer = pointer;
      this.count = count;
    },
    /**
     * 一键清除画板
     */
    handleClear() {
      // 还未初始化
      if (!this.w) return;
      this.w.clear();
      this.$emit("clear", this.number);
      this.$message.success(this.$t("el.teaching.clearSuccess"));
    },
    /**
     * 撤销
     */
    handleBack() {
      // 还未初始化
      if (!this.w) return;
      try {
        this.w.back();
      } catch (e) {
        this.$message.warning(e.message);
      }
    },
    /**
     * 撤销
     */
    handleRestore() {
      // 还未初始化
      if (!this.w) return;
      try {
        this.w.restore();
      } catch (e) {
        this.$message.warning(e.message);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.tools {
  position: fixed;
  top: auto;
  left: auto;
  bottom: 40px;
  right: calc(50% - 373px);
  height: 36px;
  width: 746px;
  background: rgba(0, 0, 0, 0.46);
  border-radius: 6px;
  font-size: 0;
  white-space: nowrap;
  line-height: 28px;
  vertical-align: middle;
  padding: 9px 0;
  user-select: none;
  & > div {
    display: inline-block;
    color: rgba(255, 255, 255, 1);
    vertical-align: middle;
    line-height: 28px;
    height: 28px;
    cursor: pointer;
    & > span {
      font-size: 15px;
      letter-spacing: 1px;
      display: inline-block;
      vertical-align: middle;
    }
  }
  .el-color-picker {
    height: auto;
    vertical-align: middle;
    /deep/ .el-color-picker__trigger {
      width: auto;
      height: auto;
      padding: 0;
      border: none;
    }
    /deep/ .el-color-picker__color {
      width: 16px;
      height: 16px;
      box-sizing: content-box;
      padding: 0;
      border-radius: 50%;
      overflow: hidden;
      border: 2px solid #fff;
      margin: 4px 0;
    }
    /deep/ .el-color-picker__icon {
      display: none;
    }
  }
  .el-slider {
    width: 90px;
    /deep/ .el-slider__runway {
      background-color: #fff;
      height: 4px;
      border-radius: 2px;
      margin: 12px 0;
    }
    /deep/ .el-slider__bar {
      background-color: @themeBlue;
      height: 4px;
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;
    }
    /deep/ .el-slider__button {
      border: 1px solid @themeBlue;
      width: 12px;
      height: 12px;
    }
    /deep/ .el-slider__button-wrapper {
      width: 28px;
      height: 28px;
      top: -12.5px;
    }
  }
  .pen {
    margin-left: 38px;
    opacity: 0.6;
    &.active {
      opacity: 1;
    }
  }
  .rubber {
    margin-left: 28px;
    opacity: 0.6;
    &.active {
      opacity: 1;
    }
  }
  .palette {
    margin-left: 36px;
    .colorselect {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      border: 1px solid #ccc;
    }
  }
  .slider {
    margin-left: 30px;
    margin-right: 6px;
  }
  .back {
    margin-left: 26px;
  }
  .restore {
    margin-left: 24px;
  }
  .clear {
    margin-left: 24px;
  }
  .switch {
    margin-left: 36px;
    margin-right: 36px;
  }
  .inline {
    margin-left: 26px;
    width: 1px;
    height: 16px;
    background: rgba(255, 255, 255, 0.6);
  }
}
// 笔
.icon-pen {
  width: 28px;
  height: 28px;
  vertical-align: middle;
  .gen-icon("painting_panint");
}
// 橡皮檫
.icon-rubber {
  width: 28px;
  height: 28px;
  vertical-align: middle;
  .gen-icon("painting_rubber");
}
// 撤销
.icon-back {
  width: 18px;
  height: 18px;
  vertical-align: middle;
  margin-right: 6px;
  .gen-icon("painting_revoke");
}
// 恢复
.icon-restore {
  width: 18px;
  height: 18px;
  vertical-align: middle;
  margin-right: 6px;
  .gen-icon("painting_recovery");
}
// 一键清除
.icon-clear {
  width: 18px;
  height: 18px;
  vertical-align: middle;
  margin-right: 6px;
  .gen-icon("painting_Eliminate");
}
//颜色选择器
.allselect {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  // width: 76px;
  .selectcolor {
    width: 20px;
    height: 20px;
    border: 1px solid #ccc;
    img {
      width: 12px;
      height: 12px;
      margin: 4px;
    }
  }
}
.red {
  background: #e8322f;
}
.green {
  background: #1fbb7d;
}
.blue {
  background: #4178f9;
}
.white {
  background: #ffffff;
}
.black {
  background: #000000;
}
</style>
