<template>
  <!-- 备授课框架页 -->

  <el-container class="classroom" :class="pageType">
    <el-header v-if="!fullScreen && !isCompare && !isHidePrepareMenu">
      <div class="center flex flex-m flex-c">
        <span
          class="title text-overflow"
          :title="knowledgeData.knowledgeName"
          >{{ knowledgeData.knowledgeName }}</span
        >
        <span v-if="knowledgeData.isTeacherTrain" class="classroom-train"
          >师训</span
        >
        <!-- <div
          class="btn-switch"
          @click="$refs.knowledgeSwitch.init(knowledgeData.courseId)"
        >
          <i class="icon-navtop-switch"></i>
          <span>切换</span>
        </div> -->
      </div>
      <!-- <div class="f-l left">
        <router-link to="/" tag="button" type="button" class="btn-back">
          <i class="icon-navtop-back"></i>返回
        </router-link>
      </div> -->
      <div class="f-l right">
        <template v-if="isKj || isJA">
          <!-- 是之前Office的加速插件，现在已经没有用了
          <el-tooltip
            :value="promptTipShow"
            placement="bottom"
            :offset="-64"
            manual
            popper-class="tooltip--prompt-assistant"
          >
            <div slot="content" class="prompt-assistant">
              <div class="msg">帮助课件流畅播放的小助手~</div>
              <button type="button" @click="toInstall">前往安装</button>
              <img src="@/assets/images/pic_figure@2x.png" />
            </div>
          </el-tooltip> -->
          <el-button
            v-if="isKj"
            v-check-bsk-permission="{
              handler: onStartTeaching,
              args: {
                courseId: $route.query.courseId,
                originTenantId: $route.query.originTenantId,
              },
            }"
            class="btn-start"
            >开始授课</el-button
          >
          <!-- 判断是否在 iframe 中 -->
          <!-- <el-button class="btn-fullscreen" @click="toggle">
            <img src="@/assets/images/icon/icon_fullscreen.png" />
            <span>全屏备课</span>
          </el-button> -->
          <el-button class="btn-fullscreen" @click="toCompareLesson">
            <img src="@/assets/images/icon/icon_compare.png" />
            <span>{{ $t("el.classroomWps.compareLessonPreparation") }}</span>
          </el-button>
        </template>
        <!-- <template v-if="isJA">
          <el-button class="btn-fullscreen" @click="wordtoggle">
            <img src="@/assets/images/icon/icon_fullscreen.png" />
            <span>全屏备课</span>
          </el-button>
        </template> -->
      </div>
    </el-header>
    <el-container>
      <el-aside v-if="!isHidePrepareMenu && !isCompare">
        <el-scrollbar class="el-scrollbar_x">
          <div class="navleft-container" style="padding-top: 1px">
            <!-- <dl
              v-for="(item, index) in navLeft"
              :key="index"
              :class="'navleft ' + item.type"
            > -->
            <dl
              v-for="(item, index) in navLeft"
              :key="index"
              class="navleft bk"
            >
              <!-- <dt>
                <div class="title">{{ item.title }}</div>
              </dt> -->
              <router-link
                v-for="(menu, i) in item.children"
                :key="i"
                :to="{
                  path: menu.path,
                  query: { ...$route.query, resourceId: undefined },
                }"
                :class="{
                  active: $route.path === menu.path,
                }"
                @click.native="toChangePath(menu)"
                tag="dd"
                replace
                style="position: relative"
              >
                <i :class="'icon-navleft-' + menu.icon"></i>
                <span>{{ menu.title }}</span>
                <div class="navleft-no-resource" v-if="menu.hasResource === 1">
                  无资源
                </div>
              </router-link>
            </dl>
            <!-- <div class="footer">
              <button
                type="button"
                @click="$utils.openWindow('manage/classManage')"
              >
                班级管理
              </button>
            </div> -->
          </div>
        </el-scrollbar>
        <!-- 折叠 -->
        <div
          v-show="listHide"
          class="file-list__toggle flex flex-m flex-c"
          @click="listHide = !listHide"
        >
          {{ listHide ? "展开" : "收起" }}列表
        </div>
      </el-aside>

      <el-main :class="isCompare ? 'preview-main' : ''">
        <fullscreen
          ref="previewMain"
          class="flex"
          style="width: 100%; height: 100%"
          @change="onFullscreenChange"
        >
          <compare-files
            :showPreviewDialog="isCompare"
            :fileType="compareFileType"
            :file="compareFile"
            :playUrl="comparePlayUrl"
            :courseId="knowledgeData.courseId"
          ></compare-files>
          <div v-if="previewShow">
            <el-image-viewer
              style="color: #fff"
              :on-close="
                () => {
                  previewShow = false;
                }
              "
              :url-list="[previewImg]"
            />
          </div>

          <div
            v-show="isCompare"
            @click="toCompareLesson"
            class="change-resource-btn"
          >
            {{ $t("el.classroomWps.changeResource") }}
          </div>

          <el-scrollbar class="el-scrollbar_x" :style="preViewWidth">
            <file-list
              :id="id"
              :course-id="knowledgeData.courseId"
              :file-object="fileObject"
              :file-list="fileList"
              :loading="fileListLoad"
              :file="currentFile"
              :show-upload="pageInfo.isUpload"
              :upload-button-text="pageInfo.uploadButtonText"
              :accept="pageInfo.accept"
              :format-limit="pageInfo.formatLimit"
              :max-size="pageInfo.maxSize"
              :type="pageInfo.type"
              :colorType="colorType"
              :route-type="type"
              :list-hide.sync="listHide"
              :isCompare="isCompare"
              :fileChangedInC="fileChangedInC"
              @onFullscreenChange="onFullscreenChange"
              @change="setFile"
              @refresh="fetchFileList"
              @changeNewnCount="changeNewnCount"
              @enable-boot="startGuide"
              @change-introjs="changeIntrojs"
              :courseware="courseware"
              ref="fileList"
            ></file-list>
          </el-scrollbar>
          <!-- 对照退出按钮 -->
          <div v-if="isCompare || previewShow" class="whiteboard-exit">
            <div class="exit-screen" @click.stop="compareToggle">
              <img v-if="fullScreen" src="~@/assets/images/icon/exitview.png" />
              <img v-else src="~@/assets/images/icon/fullview.png" />
            </div>
            <div></div>
            <div class="exit-course" @click="exitVisible = true">
              <img src="~@/assets/images/icon/quitaccount.png" />
            </div>
          </div>
          <!-- 提示确定退出对照 -->
          <el-dialog
            width="464px"
            center
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :modal-append-to-body="false"
            :visible.sync="exitVisible"
          >
            <div slot="title" class="el-dialog_title">
              {{ $t("el.teaching.confirmExit") }}
            </div>
            <div class="el-dialog__content">
              {{ $t("el.classroomWps.sureComparison") }}
            </div>
            <span slot="footer">
              <el-button @click="exitVisible = false">{{
                $t("el.common.cancel")
              }}</el-button>
              <el-button type="primary" @click="handleExitCompare">{{
                $t("el.classroomWps.ExitControl")
              }}</el-button>
            </span>
          </el-dialog>
          <compare-lession-dialog
            :showCompareDialog="showCompareDialog"
            :id="id"
            :compareResourceId="compareResourceId + ''"
            :resourceId="$route.query.resourceId + ''"
            @selectBack="selectBack"
            @close-dialog="showCompareDialog = false"
          ></compare-lession-dialog>
        </fullscreen>
      </el-main>
    </el-container>
    <!-- 切换知识点 -->
    <knowledge-switch
      ref="knowledgeSwitch"
      is-switch
      :current-id="id"
      :isTry="knowledgeData.isTry"
      @selected="setKnowledge"
    ></knowledge-switch>
    <!-- 引导页 -->
    <el-dialog width="744.5px" center :visible.sync="dialogGuideVisible">
      <div class="dialog-header">
        <div class="flex">
          <div class="dialog-header-img">
            <img src="@/assets/images/pic_upgrade@2x.png" />
          </div>
          <div>
            <div class="dialog-header-title">课件修改功能升级啦</div>
            <div class="dialog-header-tip">
              平台课件、个人课件均可修改，还支持音视频
            </div>
          </div>
        </div>
      </div>
      <div class="dialog-content">
        <swiper class="banner" :options="swiperOption" ref="mySwiper">
          <swiper-slide
            class="swiper-item"
            v-for="item of swiperList"
            :key="item.id"
          >
            <img class="swiper-img" :src="item.imgUrl" />
          </swiper-slide>
          <!-- 如果需要导航按钮 -->
          <div class="swiper-button-prev" slot="button-prev"></div>
          <div
            class="swiper-button-next"
            slot="button-next"
            ref="nextSwiper"
          ></div>
        </swiper>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="dialogGuideVisible = false"
          v-if="isShowBtn"
          >我知道了</el-button
        >
        <el-button type="primary" @click="nextSwiper" v-else>下一步</el-button>
      </div>
    </el-dialog>
  </el-container>
  <!-- <Teach v-if="fullscreenstate"/> -->
</template>

<script>
import Vue from "vue";
import Teach from "../teaching/indexHd.vue";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import axios from "@/plugins/axios.js";
import { queryResource, getResourceCount } from "@/api/classroom";
import knowledgeSwitch from "@/views/components/knowledge-switch.vue";
import FileList from "./components/file-list.vue";
import mixinKnowledgeInfo from "@/mixin/mixin-knowledge-info.js";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.min.css";
import { addDownloadLikeRecord } from "@/api/classroom/index.js";
import { getQueryResourceAndClass } from "@/api/classroom/bk-kj";
import screenfull from "screenfull";
import CompareLessionDialog from "@/components/biz-components/CompareLessionDialog";
import CompareFiles from "../../components/biz-components/CompareFiles";

import { getHidePrepareMenu } from "@/core/auth";
import _ from "lodash";

import fullscreen from "vue-fullscreen";
Vue.use(fullscreen);

const SIMULATED_DATA = {
  collectiveList: [
    {
      author: "韩梅梅",
      category: 3,
      courseName: "",
      createTime: null,
      creator: "",
      creatorName: "",
      desc: true,
      downLoaded: "",
      editor: "",
      endSaveTime: "",
      endTime: "",
      fileName: "",
      format: "",
      grade: "钢铁侠一年级",
      gradeId: "",
      isOwner: 0,
      knowledgeName: "",
      lessonStatus: "",
      likeCount: 123,
      likeStatus: 0,
      limit: 10,
      offset: 1,
      order: "",
      organizationCode: "",
      physicalDelete: null,
      realOffset: 0,
      remark: "",
      requestTime: "",
      resourceId: "_2251",
      resourceModifyTime: null,
      resourceName: "长度与体积的测量.pptx",
      resourceType: "KJ",
      resourceUrl: "group1/M00/01/37/CgoVdl-bxd2EMgpsAAAAAKuhwXU14.pptx",
      retentionTime: null,
      schoolName: "",
      sort: "",
      startSaveTime: "",
      startTime: "",
      subjectId: "",
      subjects: "计算机",
      term: "上学期",
      transactionCode: "",
      updateTime: null,
    },
  ],
  standardList: [
    {
      author: "韩梅梅",
      category: 0,
      courseName: "",
      createTime: null,
      creator: "",
      creatorName: "",
      desc: true,
      downLoaded: "",
      editor: "",
      endSaveTime: "",
      endTime: "",
      fileName: "",
      format: "",
      grade: "钢铁侠一年级",
      gradeId: "",
      isOwner: 0,
      knowledgeName: "",
      lessonStatus: "",
      likeCount: 123,
      likeStatus: 0,
      limit: 10,
      offset: 1,
      order: "",
      organizationCode: "",
      physicalDelete: null,
      realOffset: 0,
      remark: "",
      requestTime: "",
      resourceId: "_2056",
      resourceModifyTime: null,
      resourceName: "第1节：长度的测量.pptx",
      resourceType: "KJ",
      resourceUrl: "group1/M00/01/2F/CgoVdl-RSUOEWr3kAAAAAKuhwXU88.pptx",
      retentionTime: null,
      schoolName: "",
      sort: "",
      startSaveTime: "",
      startTime: "",
      subjectId: "",
      subjects: "计算机",
      term: "上学期",
      transactionCode: "",
      updateTime: null,
    },
    {
      author: "韩梅梅",
      category: 0,
      courseName: "",
      createTime: null,
      creator: "",
      creatorName: "",
      desc: true,
      downLoaded: "",
      editor: "",
      endSaveTime: "",
      endTime: "",
      fileName: "",
      format: "",
      grade: "钢铁侠一年级",
      gradeId: "",
      isOwner: 0,
      knowledgeName: "",
      lessonStatus: "",
      likeCount: 20,
      likeStatus: 0,
      limit: 10,
      offset: 1,
      order: "",
      organizationCode: "",
      physicalDelete: null,
      realOffset: 0,
      remark: "",
      requestTime: "",
      resourceId: "_2056",
      resourceModifyTime: null,
      resourceName: "第2节：体积的测量.pptx",
      resourceType: "KJ",
      resourceUrl: "group1/M00/01/2F/CgoVdl-RSUOEWr3kAAAAAKuhwXU88.pptx",
      retentionTime: null,
      schoolName: "",
      sort: "",
      startSaveTime: "",
      startTime: "",
      subjectId: "",
      subjects: "计算机",
      term: "上学期",
      transactionCode: "",
      updateTime: null,
    },
  ],
  personalList: [
    {
      author: "李雷",
      category: 1,
      courseName: "",
      createTime: null,
      creator: "",
      creatorName: "",
      desc: true,
      downLoaded: "",
      editor: "",
      endSaveTime: "",
      endTime: "",
      fileName: "",
      format: "",
      grade: "钢铁侠一年级",
      gradeId: "",
      isOwner: 1,
      knowledgeName: "",
      lessonStatus: "",
      likeCount: 0,
      likeStatus: 0,
      limit: 10,
      offset: 1,
      order: "",
      organizationCode: "",
      physicalDelete: null,
      realOffset: 0,
      remark: "",
      requestTime: "",
      resourceId: "_2130",
      resourceModifyTime: null,
      resourceName: "【课件】第一单元.pptx",
      resourceType: "KJ",
      resourceUrl: "group1/M00/01/35/CgoVdl-Wn7WEfgjKAAAAALWfCjo26.pptx",
      retentionTime: null,
      schoolName: "",
      sort: "",
      startSaveTime: "",
      startTime: "",
      subjectId: "",
      subjects: "计算机",
      term: "上学期",
      transactionCode: "",
      updateTime: null,
    },
  ],
};

export default {
  name: "hdClassroomWps",
  mixins: [mixinKnowledgeInfo],
  props: {
    // eslint-disable-next-line vue/require-prop-type-constructor
    colorType: "",
    /**
     * 备课类型
     */
    type: {
      type: String,
      default: "bk-ja",
    },
    /**
     * 知识点Id
     */
    id: String,
    courseId: {
      type: [String, Number],
      default: "",
    },
    originTenantId: {
      type: [String, Number],
      default: "",
    },
    originTenantType: {
      type: [String, Number],
      default: "",
    },
  },
  components: {
    Swiper,
    SwiperSlide,
    knowledgeSwitch,
    FileList,
    CompareLessionDialog,
    CompareFiles,
    "el-image-viewer": () =>
      import("element-ui/packages/image/src/image-viewer"),

    // Teach,
  },
  data() {
    var acceptKj = [
      "application/vnd.ms-powerpoint", // ppt
      "application/vnd.openxmlformats-officedocument.presentationml.presentation", // pptx
    ];
    var formatLimitKj = ["ppt", "pptx", "pdf", "xls", "xlsx"];
    var formatLimitKj2 = ["ppt", "pptx", "pdf"]; // 课件不支持上传excel
    var formatLimitKtzl = [];
    var acceptJa = [
      "application/msword", // doc
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // docx
    ];
    var formatLimitJa = ["doc", "docx"];
    return {
      fullScreen: false, // 全屏状态
      isInnerIframe: false,
      isHidePrepareMenu: false, // 是否隐藏 备课页 Menu
      fullscreenstate: false,
      isShowBtn: false,
      checkResourceEmpty: [], // 左侧tab 是否有资源合集
      swiperOption: {
        // autoplay:true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      swiperList: [
        {
          id: "001",
          imgUrl: require("@/assets/images/guide1.gif"),
        },
        {
          id: "002",
          imgUrl: require("@/assets/images/guide2.gif"),
        },
      ],
      promptTipShow: false, // 提示助手显示
      navLeft: [
        // 左侧菜单
        {
          title: "备课四件套",
          type: "bk",
          children: [
            {
              title: "课件",
              icon: "kj",
              path: "/hdClassroomWps/bk-kj",
              type: "KJ",
              pageType: "bk-kj",
              isUpload: true,
              uploadButtonText: "上传个人课件",
              // accept: acceptKj,
              formatLimit: formatLimitKj2,
              maxSize: process.env.VUE_APP_WPS_FILE_LIMIT * 1024 * 1024,
              hasResource: -1,
            },
            {
              title: "教案",
              icon: "ja",
              path: "/hdClassroomWps/bk-ja",
              type: "JA",
              pageType: "bk-ja",
              isUpload: true,
              maxSize: process.env.VUE_APP_WPS_FILE_LIMIT * 1024 * 1024,
              uploadButtonText: "上传个人教案",
              // accept: acceptJa,
              formatLimit: formatLimitJa.concat(formatLimitKj),
              hasResource: -1,
            },
            {
              title: "课堂资料",
              icon: "ktzl",
              path: "/hdClassroomWps/bk-ktzl",
              type: "KTZL",
              pageType: "bk-ktzl",
              isUpload: true,
              uploadButtonText: "上传课堂资料",
              // accept: acceptKj,
              formatLimit: formatLimitKtzl,
              maxSize: process.env.VUE_APP_KTZL_FILE_LIMIT * 1024 * 1024,
              hasResource: -1,
            },
            {
              title: "备课视频",
              icon: "bksp",
              path: "/hdClassroomWps/bk-bksp",
              type: "BKSP",
              pageType: "bk-bksp",
              isUpload: false,
              hasResource: -1,
            },
            {
              title: "课堂实录",
              icon: "ktsl",
              path: "/hdClassroomWps/bk-ktsl",
              type: "KTSL",
              pageType: "bk-ktsl",
              isUpload: false,
              hasResource: -1,
            },
          ],
        },
        {
          title: "授课三件套",
          type: "sk",
          children: [
            {
              title: "学习单", // 原来的作业修改为学习单
              icon: "zy",
              path: "/hdClassroomWps/sk-zy",
              type: "ZY",
              pageType: "sk-zy",
              isUpload: true,
              maxSize: process.env.VUE_APP_WPS_FILE_LIMIT * 1024 * 1024,
              uploadButtonText: "上传个人资源",
              // accept: acceptJa,
              formatLimit: formatLimitJa.concat(formatLimitKj),
              hasResource: -1,
            },
            {
              title: "作业", // 原来的课前预习因为需求改为作业
              icon: "kqyx",
              path: "/hdClassroomWps/sk-kqyx",
              type: "KQYX",
              pageType: "sk-kqyx",
              isUpload: true,
              maxSize: process.env.VUE_APP_WPS_FILE_LIMIT * 1024 * 1024,
              uploadButtonText: "上传个人资源",
              // accept: acceptJa,
              formatLimit: formatLimitJa.concat(formatLimitKj),
              hasResource: -1,
            },
            // {
            //   title: "课件",
            //   icon: "kj",
            //   path: "/hdClassroomWps/sk-kj",
            //   type: "KJ",
            //   pageType: "sk-kj",
            //   isUpload: true,
            //   uploadButtonText: "上传个人课件",
            //   // accept: acceptKj,
            //   formatLimit: formatLimitKj2,
            //   maxSize: 500 * 1024 * 1024,
            // },
          ],
        },
      ],
      fileList: [], // 文件列表
      fileObject: {}, // 文件列表 标准，集中，个人
      fileListLoad: false, // 文件列表加载中
      lastFetchFileListId: 0, // 最后一次获取文件列表的接口id
      currentFile: null, // 当前选中的文件
      fileType: "", // 资源类型
      listHide: false, // 列表隐藏
      lockMonitor: false, // 锁定监控
      needCheckResource: true, // 是否要检测资源是否存在，是否有权限
      dialogGuideVisible: false, // 引导页
      getfrenshListState: false,
      showCompareDialog: false, // 对照 dialog
      isCompare: false, // 是否是对照模式
      preViewWidth: "width: 100%",
      compareFileType: "", // 对照文件资源类型
      compareFile: null, // 对照选中的文件
      comparePlayUrl: "", // 对照 url
      previewImg: "", // 图片url
      compareResourceId: "", // 对照资源 id
      exitVisible: false, // 退出备课弹框
      previewShow: false,
    };
  },
  provide() {
    const self = this;
    return {
      reactiveMsg: () => {
        return {
          courseId: self.$route.query.courseId || "",
          originTenantId: self.$route.query.originTenantId || "",
          originTenantType: self.$route.query.originTenantType || "",
          courseTenantInfoId: self.$route.query.courseTenantInfoId || "",
        };
      },
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      // documentHidden: (state) => state.documentHidden, // 当前窗口隐藏
      windowFocus: (state) => state.windowFocus, // 当前窗口是否获取焦点
      monitorId: (state) => state.prepare.monitorId, // 备课监控id
    }),
    ...mapGetters([
      "isNeedGuide", // 是否需要引导
    ]),
    // swiper实例，调用api时使用 this.swiper.xxx
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
    /**
     * 当前页面类型
     */
    pageType() {
      const res = this.navLeft.find((item) => {
        return (
          item.children &&
          item.children.find((item) => item.path === this.$route.path)
        );
      });
      return res ? res.type : "";
    },
    /**
     * 是否是课件
     */
    isKj() {
      return (
        ["/hdClassroomWps/bk-kj", "/hdClassroomWps/sk-kj"].indexOf(
          this.$route.path
        ) !== -1
      );
    },
    /**
     * 是否教案
     */
    isJA() {
      return ["/hdClassroomWps/bk-ja"].indexOf(this.$route.path) !== -1;
    },
    /**
     * 页面信息
     */
    pageInfo() {
      var res = {};
      // eslint-disable-next-line no-label-var
      top: for (let item of this.navLeft) {
        for (let value of item.children) {
          if (value.path === this.$route.path) {
            res = value;
            break top;
          }
        }
      }
      return res;
    },
    /**
     * 课件对象
     */
    courseware() {
      if (!this.currentFile) return null;
      let file = this.currentFile;
      return {
        schoolName: this.user.schoolName, // 学校名称
        knowledgeId: this.id, // 知识点id
        resourceName: file.resourceName, // 资源文件名
        category: file.category, // 课件来源
        staffName: this.user.staffName, // 用户名
        resourceId: file.resourceId, // 资源类型
        resourceType: file.resourceType, // 课件类型
        courseId: this.courseId,
        originTenantId: this.originTenantId,
        originTenantType: this.originTenantType,
        courseTenantInfoId:
          this.courseTenantInfoId || this.$route.query.courseTenantInfoId,
      };
    },
    /**
     * 是否检测资源是否存在、有权限。
     */
  },
  watch: {
    "pageInfo.type": {
      handler() {
        this.fileObject = {};
        // this.fileList=[];
        this.fetchFileList();
        console.log("pageInfo.type", this.pageInfo.type);
        if (this.pageInfo.type === "KJ" && this.user.checkPlugin) {
          this.promptTipShow = true;
          this.$store.dispatch("recordChecked");
        }
      },
      immediate: true,
    },
    $route(Value) {
      this.fileType = Value.params.type.split("-")[1];
      if (Value.query && Value.query.resourceId)
        this.setResourceId(Value.query.resourceId);
    },
    /**
     * 课件类型更改显示列表
     */
    type() {
      this.listHide = false;
    },
    windowFocus: "changeWindowFocus",
    // documentHidden: "changeDocumentHidden",
  },
  created() {
    // this.$alert('', '请更换浏览器', {
    //   center: true,
    //   customClass: 'el-message-box--confirm el-message-box--danger',
    //   message: this.$createElement('div', {
    //     'class': ''
    //   }, '当前浏览器无法正常使用此功能，推荐使用：Google Chrome'),
    //   showConfirmButton: false,
    // }).then(() => {
    // }).catch(() => {
    // });

    this.getResourceCount();

    this.setPrepareFn((val) => {
      console.log("vallllllll", val);
      this.$router.go(-1);
    });
  },
  mounted() {
    let self = this;
    this.isInnerIframe = window.self !== window.top;
    // === "true"
    // console.log("getHidePrepareMenu=====", typeof getHidePrepareMenu());
    this.isHidePrepareMenu =
      getHidePrepareMenu() === "true" || getHidePrepareMenu() === true;
    this.fileType = this.$route.params.type.split("-")[1];
    this.setResourceId(this.$route.query.resourceId);
    this.initPageActionListener();
    this.initRefreshMonitor();
    window.addEventListener("message", this.fullScreenMessage);
    if (this.isHidePrepareMenu) {
      this.listHide = true;
    }
  },
  destroyed() {
    this.removePageActionListener();
    window.addEventListener("message", this.fullScreenMessage);
    this.endPrepare(false);
  },
  methods: {
    ...mapMutations(["setResourceId", "setPrepareFn"]),
    ...mapActions([
      "startPrepare",
      "endPrepare",
      "keepPrepare",
      "triggerGuide",
      "setPageAction",
      "setPageFocusAction",
    ]), // 监控api
    // 全屏回调
    onFullscreenChange(fullscreen) {
      console.log("fullscreen=====", fullscreen);
      this.fullScreen = fullscreen;
    },

    fileChangedInC(newVal, oldVal) {
      if (!this.needCheckResource) return;
      if (this.lockMonitor) return;
      if (newVal === oldVal) return; // 同一个文件不做监控
      if (!this.windowFocus) return; // 未获取焦点，不监控
      // if (this.documentHidden) return; // 页面隐藏，不监控
      if (this.monitorId) {
        // 监控中
        let courseware = this.courseware;
        this.endPrepare(false).finally(() => {
          this.setPageAction(courseware); // 初始化监控倒计时
          // 结束备课
          // this.startPrepare(courseware); // 开始备课
        });
      } else {
        this.setPageAction(this.courseware); // 初始化监控倒计时
        // this.startPrepare(this.courseware); // 开始备课
      }
    },

    // 对照课程 type: 0 视频  1 wps 2 图片
    selectBack(obj, type) {
      console.log("obj=====", obj);

      this.listHide = true;
      this.showCompareDialog = false;
      this.preViewWidth = "width: 50%; margin-left:auto; margin-right: 0";
      this.compareFileType = type;
      this.compareFile = obj.resource;
      this.compareResourceId = obj.resourceId;
      if (type === "wpsFile") {
        this.isCompare = true;
        // if (!this.fullScreen) {
        //   this.compareToggle();
        // }
      } else if (type === "video" || type === "audio") {
        this.isCompare = true;

        this.comparePlayUrl = obj.playUrl;
      } else {
        this.isCompare = true;

        this.comparePlayUrl = obj.playUrl;
        // this.previewShow = true;
        // this.previewImg = obj.playUrl;
      }
    },

    handleExitCompare() {
      this.isCompare = false;
      this.listHide = false;
      this.exitVisible = false;
      this.preViewWidth = "width: 100%;";
      this.compareFileType = "";
      this.compareFile = {};
      this.compareResourceId = "";
      if (this.fullScreen) {
        this.compareToggle();
      }
    },
    /**
     * 对照全屏
     */
    compareToggle() {
      this.$refs.previewMain && this.$refs.previewMain.toggle();
    },
    // 对照备课弹窗
    toCompareLesson() {
      if (!this.currentFile) {
        this.$message.warning(this.$t("el.classroomWps.UnableToCompare"));
        return;
      }
      this.showCompareDialog = true;
    },
    getResourceCount() {
      getResourceCount({ knowledgeId: this.id })
        .then((result) => {
          this.checkResourceEmpty = result.data;
          this.navLeft.forEach((element) => {
            element.children.forEach((ele) => {
              ele.hasResource = this.checkResourceEmpty[ele.type];
            });
          });
        })
        .catch(() => {
          //
          this.checkResourceEmpty = [];
        });
    },

    /**
     * 改变子元素的折叠或展开
     */
    changeChild() {
      let resid = this.$route.query.resourceId;
      let restype = this.$route.params.type.replace("-", "");
      if (resid) {
        if (this.fileObject.standardList.length > 2) {
          for (let i = 2; i < this.fileObject.standardList.length; i++) {
            if (this.fileObject.standardList[i].resourceId == resid) {
              this.userotueInfo(restype, 1);
              break;
            }
          }
        }
        if (this.fileObject.collectiveList.length > 2) {
          for (let i = 2; i < this.fileObject.collectiveList.length; i++) {
            if (this.fileObject.collectiveList[i].resourceId == resid) {
              this.userotueInfo(restype, 2);
              break;
            }
          }
        }
      }
    },
    toChangePath(menu) {
      this.$router.replace({
        path: menu.path,
        query: { ...this.$route.query, resourceId: undefined },
      });
    },

    /**
     * 根据路由状态改变子组件状态
     * @where 1.集团资源 2.学校资源
     * @type 当前的页面路由信息
     */
    userotueInfo(type, where) {
      if (where === 1) {
        switch (type) {
          case "bkkj":
            this.$refs.fileList.bkkjstate = false;
            break;
          case "bkja":
            this.$refs.fileList.bkjastate = false;
            break;
          case "skkqyx":
            this.$refs.fileList.skkqyxstate = false;
            break;
          case "skkj":
            this.$refs.fileList.skkjstate = false;
            break;
          case "skzy":
            this.$refs.fileList.skzystate = false;
            break;
        }
      }
      if (where === 2) {
        switch (type) {
          case "bkkj":
            this.$refs.fileList.bkkjschstate = false;
            break;
          case "bkja":
            this.$refs.fileList.bkjaschstate = false;
            break;
          case "skkqyx":
            this.$refs.fileList.skkqyxschstate = false;
            break;
          case "skkj":
            this.$refs.fileList.skkjschstate = false;
            break;
          case "skzy":
            this.$refs.fileList.skzyschstate = false;
            break;
        }
      }
    },
    /**
     * 点赞，下载更新关联id的数量
     * @index 1:平台点击 2：学校资源点击  3:个人资源下载
     * @data 关联的id
     * @num 最新数量
     * @type 1:点赞，2:下载
     */
    changeNewnCount(data, index, num, type) {
      if (type === 1) {
        if (index === 1) {
          // 平台点击,改变学校资源
          let collectindex = _.findIndex(this.fileObject.collectiveList, [
            "resourceId",
            data,
          ]);
          this.fileObject.collectiveList[collectindex].likeCount = num;
          if (this.fileObject.collectiveList[collectindex].likeStatus === 0) {
            this.fileObject.collectiveList[collectindex].likeStatus = 1;
          } else {
            this.fileObject.collectiveList[collectindex].likeStatus = 0;
          }
        }
        if (index === 2) {
          // 学校资源点击
          let collectindex = _.findIndex(this.fileObject.standardList, [
            "resourceId",
            data,
          ]);
          this.fileObject.standardList[collectindex].likeCount = num;
          if (this.fileObject.standardList[collectindex].likeStatus === 0) {
            this.fileObject.standardList[collectindex].likeStatus = 1;
          } else {
            this.fileObject.standardList[collectindex].likeStatus = 0;
          }
        }
      }
      if (type === 2) {
        if (index === 1) {
          // 平台点击,改变学校资源
          let collectindex = _.findIndex(this.fileObject.collectiveList, [
            "resourceId",
            data[0],
          ]);
          this.fileObject.collectiveList[collectindex].downloadCount++;
        }
        if (index === 2) {
          // 学校资源点击
          let collectindex = _.findIndex(this.fileObject.standardList, [
            "resourceId",
            data[0],
          ]);
          this.fileObject.standardList[collectindex].downloadCount++;
        }
        if (index === 3) {
          let collectindex = null; // 改变平台下载数
          let collectindex2 = null; // 改变学校资源下载数
          for (let i = 0; i <= data.length; i++) {
            collectindex = _.findIndex(this.fileObject.standardList, [
              "resourceId",
              data[i],
            ]);
            if (collectindex != -1) {
              break;
            }
          }
          for (let i = 0; i <= data.length; i++) {
            collectindex2 = _.findIndex(this.fileObject.collectiveList, [
              "resourceId",
              data[i],
            ]);
            if (collectindex2 != -1) {
              break;
            }
          }
          if (collectindex != -1) {
            this.fileObject.standardList[collectindex].downloadCount++;
          }
          if (collectindex2 != -1) {
            this.fileObject.collectiveList[collectindex2].downloadCount++;
          }
        }
      }
    },
    /**
     * 获取文件列表数据
     * @param {*} lock 锁定监控
     */
    fetchFileList(lock = false) {
      // this.fileList=[];
      if (lock) this.lockMonitor = true;
      this.fileListLoad = true;
      var uid = ++this.lastFetchFileListId;
      this.getResourceCount(); // 更新有无资源标识
      queryResource(this.pageInfo.type, {
        knowledgeId: this.id,
        originTenantId: this.originTenantId,
      })
        .then(({ data }) => {
          if (uid === this.lastFetchFileListId) {
            this.fileObject = data;
            this.fileList = [];
            this.fileList.push(...this.fileObject.standardList);
            this.fileList.push(...this.fileObject.collectiveList);
            this.fileList.push(...this.fileObject.personalList);
            this.changeChild();
          }
          if (!this.monitorId) {
            this.lockMonitor = false;
          }
          this.$nextTick()
            .then(() => {
              // 自动打开预览
              var resourceId = +this.$route.query.resourceId;
              if (resourceId) {
                var file = this.fileList.find(
                  (file) => file.resourceId === resourceId
                );
                if (file) this.currentFile = file;
              } else if (this.fileList.length) {
                // 默认打开第一个文件
                this.currentFile = this.fileList[0];
                this.$router.replace({
                  path: this.$route.path,
                  query: {
                    ...this.$route.query,
                    resourceId: this.currentFile.resourceId,
                  },
                });
              }
              return this.$nextTick();
            })
            .then(() => {
              this.lockMonitor = false;
            })
            .catch((e) => {
              this.lockMonitor = false;
              throw e;
            });
        })
        .catch((e) => {
          this.lockMonitor = false;
          throw e;
        })
        .finally(() => {
          if (uid === this.lastFetchFileListId) {
            this.fileListLoad = false;
          }
        });
    },
    /**
     * 引导下一步
     */
    nextSwiper() {
      this.$refs.nextSwiper.click();
      this.isShowBtn = true;
    },
    /**
     * 开始引导
     */
    startGuide() {
      this.fileObject = JSON.parse(JSON.stringify(SIMULATED_DATA));
      let arr = this.fileObject.standardList.slice(0);
      arr = arr.concat(this.fileObject.collectiveList);
      arr = arr.concat(this.fileObject.personalList);
      this.fileList = arr;
      this.$nextTick(() => {
        this.$refs.fileList.startIntrojs(); // 触发开始引导
      });
    },
    /**
     * 开始授课
     */
    onStartTeaching() {
      if (!this.currentFile)
        return this.$message.warning("没有课件无法授课，请上传课件后重试！");
      // this.$root.$emit('classroom-start-teaching');
      // this.$router.push({
      //   name: "teaching",
      //   query: {
      //     id: this.id,  // 知识点id
      //     resourceId: this.currentFile.resourceId,  // 资源id
      //   }
      // });
      addDownloadLikeRecord(this.currentFile.resourceId, 2)
        .then((data) => {
          this.$router.push({
            path: "/hdTeaching",
            query: {
              id: this.id,
              resourceId: this.currentFile.resourceId,
              resourceType: this.currentFile.resourceType,
              format: this.currentFile.format,
              from: "hd-bk",
              courseId: this.courseId,
              originTenantId: this.originTenantId,
              originTenantType: this.originTenantType,
              courseTenantInfoId:
                this.courseTenantInfoId || this.$route.query.courseTenantInfoId,
            },
          });
          // this.$utils.openWindow(
          //   "teaching?id=" +
          //     this.id +
          //     "&resourceId=" +
          //     this.currentFile.resourceId +
          //     "&fileType=" +
          //     this.fileType +
          //     "&format=" +
          //     this.currentFile.format,"type"
          // );
        })
        .catch(() => {
          // this.$utils.openWindow(
          //   "teaching?id=" +
          //     this.id +
          //     "&resourceId=" +
          //     this.currentFile.resourceId +
          //     "&fileType=" +
          //     this.fileType +
          //     "&format=" +
          //     this.currentFile.format,"type"
          // );
        });
    },
    /**
     * 设置知识点
     * @param {*} knowledge 知识点
     */
    setKnowledge(knowledge) {
      this.knowledgeData.knowledgeName = knowledge.knowledgeName;
      var query = this.$route.query;
      query = JSON.parse(JSON.stringify(query));
      query.resourceId = undefined;
      query.id = knowledge.id + "";
      this.$router
        .replace({
          path: this.$route.path,
          query,
        })
        .then(() => {
          this.fetchFileList();
          this.currentFile = null;
        });
    },
    /**
     * 设置当前选中文件
     * @param {*} file 文件
     */
    setFile(file) {
      this.currentFile = file;
      // if (!this.windowFocus) return;  // 未获取焦点，不监控
      // if (this.documentHidden) return;  // 页面隐藏，不监控
      // if (this.monitorId) { // 监控中
      //   this.endPrepare(false).then(() => {  // 结束备课
      //     this.startPrepare(this.courseware);  // 开始备课
      //   });
      // } else {
      //   this.startPrepare(this.courseware);  // 开始备课
      // }
    },
    /**
     * 去安装说明页面
     */
    toInstall() {
      this.$utils.openWindow("tip/install");
      this.promptTipShow = false;
    },
    /**
     * 监控页面焦点监控
     */
    changeWindowFocus() {
      console.log(this.windowFocus ? "获取焦点" : "失去焦点");
      console.log("this.monitorId", this.monitorId);
      let isStartMonitor = !!this.monitorId;
      if (this.windowFocus) {
        console.log("isStartMonitor", isStartMonitor);
        // 已获取焦点
        if (isStartMonitor) {
          // 已开始监控
          // 不做操作
          this.setPageFocusAction({ data: this.courseware, isFocus: true });
        } else {
          // 开始监控
          // this.startPrepare(this.courseware);
          // this.setPageFocusAction({ data: this.courseware, isFocus: true });
        }
      } else {
        // 未获取焦点
        if (isStartMonitor) {
          // 已开始监控
          // 停止监控
          // this.endPrepare(false);
          this.setPageFocusAction({ data: this.courseware, isFocus: false });
        } else {
          // 不做操作
        }
      }
    },
    /**
     * 监控页面是否显示
     */
    // changeDocumentHidden() {
    //   // 模拟数据
    //   if (!this.needCheckResource) return;
    //   // console.log(this.documentHidden ? '隐藏窗口' : '显示窗口');
    //   let isStartMonitor = !!this.monitorId;
    //   if (!this.documentHidden) {
    //     // 已获取焦点
    //     if (isStartMonitor) {
    //       // 已开始监控
    //       // 不做操作
    //     } else {
    //       this.setPageAction(this.courseware); // 初始化监控倒计时
    //       // 开始监控
    //       // this.startPrepare(this.courseware);
    //     }
    //   } else {
    //     // 未获取焦点
    //     if (isStartMonitor) {
    //       // 已开始监控
    //       // 停止监控
    //       this.endPrepare(false);
    //     } else {
    //       // 不做操作
    //     }
    //   }
    // },

    /**
     * 设置页面动作监听
     */
    initPageActionListener() {
      console.log("initPageActionListener");
      document.body.addEventListener("keyup", this.handleKeyUp);
      document.body.addEventListener("mousemove", this.handleMouseMove);
      document.body.addEventListener("touchstart", this.handleMouseMove);
      document.body.addEventListener("scroll", this.handleMouseMove);
      // let wpsIframe;
      // let iframeElement = await this.getWpsIframe(wpsIframe);
      // console.log("iframeElement",iframeElement);
      // console.log("this.wpsConfig.iframe",this.wpsConfig.iframe);
      // iframeElement = this.wpsConfig.iframe;
    },
    // 移除监听
    removePageActionListener() {
      document.body.removeEventListener("keyup", this.handleKeyUp);
      document.body.removeEventListener("mousemove", this.handleMouseMove);
      document.body.removeEventListener("touchstart", this.handleMouseMove);
      document.body.removeEventListener("scroll", this.handleMouseMove);
    },
    // 消息通知全屏事件
    fullScreenMessage(message) {
      try {
        let data = message.data && JSON.parse(message.data);
        if (
          (data.type === "YBX" || data.type === "XC") &&
          data.data &&
          data.data.type === "FULL_SCREEN_PREPARE"
        ) {
          if (data.data.fullScreenStatus) {
            if (!this.fullScreen) {
              this.toggleFullScreenStatus();
            }
          } else {
            if (this.fullScreen) {
              this.toggleFullScreenStatus();
            }
          }
        }
        // 接收 iframe 内 wps 动作 消息，调用 更新数据 方法
        if (
          data.type === "AIYKT" &&
          data.data &&
          data.data.type === "TEACHING_PAGE_ACTION"
        ) {
          console.log("refresh=======");
          if (data.data.refresh) {
            console.log("对照  iframe 更新了时间=========");
            this.setPageAction(this.courseware);
          }
        }
      } catch (error) {}
    },

    // 键盘
    handleKeyUp(event) {
      // console.log("kkkkkkkkkkkkkk");
      // eslint-disable-next-line no-caller
      const e = event || window.event || arguments.callee.caller.arguments[0];
      if (!e) {
        return;
      }
      const { key, keyCode } = e;
      // console.log("keyCode",keyCode);
      // console.log("key",key);
      this.setPageAction(this.courseware);
    },
    // 鼠标移动
    handleMouseMove(event) {
      this.setPageAction(this.courseware);
    },
    /**
     * 初始化刷新监听
     */
    initRefreshMonitor() {
      // 页面刷新
      window.addEventListener("beforeunload", this.handleRefreshPage);
      // 组件销毁
      this.$on("hook:destroyed", function () {
        window.removeEventListener("beforeunload", this.handleRefreshPage);
      });
    },
    /**
     * 刷新页面
     */
    handleRefreshPage() {
      // this.endPrepare(); // 结束备课
      this.endPrepare(false); // 结束备课
    },
    // 退出全屏
    toggleFullScreenStatus() {
      console.log("toggleFullScreenStatus=====");
      this.$nextTick(() => {
        this.$refs.fileList.toggle();
      });
    },
    /**
     * ppt全屏
     */
    async toggle() {
      if (this.isInnerIframe) {
        window.parent.postMessage(
          JSON.stringify({
            type: "AIYKT",
            data: {
              type: "FULL_SCREEN_PREPARE",
              fullScreen: true,
            },
          }),
          "*"
        );
        this.$refs.fileList.toggle();
      } else {
        this.$refs.fileList.toggle();
      }

      // this.$refs.fileList.$refs.filePreview.pptreadoredit
      // let _this=this;
      // await _this.wpsConfig.ready();
      // let app = _this.wpsConfig.Application;
      // // 进入幻灯片播放模式
      // await app.ActivePresentation.SlideShowSettings.Run();
    },
    /**
     * 教案全屏
     */
    wordtoggle() {
      if (this.isInnerIframe) {
        window.parent.postMessage(
          JSON.stringify({
            type: "AIYKT",
            data: {
              type: "FULL_SCREEN_PREPARE",
              fullScreen: true,
            },
          }),
          "*"
        );
        this.$refs.fileList.toggle();
      } else {
        this.$refs.fileList.toggle();
      }
    },
    /**
     * 引导对象改变
     */
    changeIntrojs(introjs) {
      this.needCheckResource = !introjs;
    },
  },
};
</script>

<style lang="less" scoped>
// 容器
.classroom {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  min-width: 1000px;
  min-height: 600px;

  // 顶部退出全屏按钮
  .whiteboard-exit {
    position: fixed;
    top: 10px;
    right: 42px;
    z-index: 100;
    font-size: 0;
    display: flex;
    .exit-screen,
    .exit-course {
      width: 36px;
      height: 36px;
      background: #646e93;
      box-shadow: 0px 4px 5px 0px rgba(91, 101, 138, 0.19);
      border-radius: 6px;
      // padding:7px;
      cursor: pointer;
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      line-height: 32px;
      opacity: 0.4;
      img {
        width: 22px;
        height: 22px;
        vertical-align: middle;
        margin: 7px;
        // &:first-child {
        //   margin-bottom: 10px;
        //   margin-right: 7px;
        // }
      }
      &:hover {
        opacity: 1;
      }
    }
    .exit-screen {
      margin-right: 10px;
    }
    .exit-course {
      background-color: #5a53f5;
    }
  }

  .preview-main {
    /deep/ .el-scrollbar__view {
      padding-bottom: 0;
    }
  }

  .quanpin {
    text-align: center;
    text-indent: 10px;
    letter-spacing: 8px;
  }
  .el-dialog_title {
    font-size: 20px;
    padding-top: 20px;
    color: #646c95;
    font-weight: bold;
  }
  .el-dialog__content {
    font-size: 19px;
    color: #646c95;
    line-height: 37px;
    letter-spacing: 1px;
    text-align: center;
    a {
      color: @themeBlue;
    }
  }
  .el-dialog__hint {
    text-align: center;
    font-size: 32px;
    color: #282828;
    line-height: 37px;
    letter-spacing: 2px;
    padding-top: 12px;
    padding-bottom: 59px;
  }
  .el-dialog__header-icon {
    text-align: left;
    img {
      width: 50px;
      height: 38px;
      cursor: pointer;
    }
  }
  .el-dialog {
    .red {
      color: #f5222d;
    }
    span {
      display: block;
      overflow: hidden;
    }
    .el-button + .el-button {
      margin-left: 36px;
      // float: right;
    }
  }
  // 对照切换资源 btn
  .change-resource-btn {
    position: fixed;
    bottom: 42px;
    left: 25%;
    transform: translateX(-25%);
    padding: 12px 38px;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 26px;
    cursor: pointer;
    font-size: 20px;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #ffffff;
  }

  .classroom-train {
    padding: 3px 12px;
    height: fit-content;
    background: rgba(90, 83, 245, 0.15);
    border-radius: 12px;
    font-size: 12px;
    font-family: PingFang-SC-Bold, PingFang-SC;
    font-weight: bold;
    color: #5a53f5;
    line-height: 17px;
    margin-left: 4px;
    margin-right: 10px;
  }

  &.sk {
    .el-header {
      .btn-start {
        background: rgba(249, 245, 255, 1);
        box-shadow: 0px 2px 4px 0px rgba(151, 86, 255, 0.12);
        background: @themeBlue;
        color: #fff;
        &:hover {
          color: #fff;
          background: @themePurple;
          background: @themeBlue;
        }
      }
    }
    .el-aside {
      .footer {
        .inline {
          &::before {
            background-color: @themePurple;
          }
        }
      }
    }
  }
  // 头部
  .el-header {
    @headerHeight: 52px;
    @rightWidth: 500px;
    @leftWidth: 500px;
    height: @headerHeight !important;
    line-height: @headerHeight;
    padding: 0;
    overflow: hidden;
    padding-left: @leftWidth;
    padding-right: @rightWidth;
    background: #fff;
    box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.03);
    position: relative;
    z-index: 3;
    .left {
      // width: @leftWidth;
      height: 100%;
      // float: left;
      // margin-left: -100%;
      position: relative;
      // right: @leftWidth;
      position: absolute;
      top: 0;
      left: 0;
    }
    .center {
      float: left;
      width: 100%;
      height: 100%;
      text-align: center;
      overflow: hidden;
      font-size: 0;
      .title {
        display: inline-block;
        font-size: 15px;
        color: #282828;
        letter-spacing: 0.9px;
        font-weight: bold;
        line-height: 20px;
        height: 20px;
        vertical-align: middle;
        max-width: calc(100% - 100px);
        white-space: nowrap;
      }
      .btn-switch {
        display: inline-block;
        height: 20px;
        color: #6f8aa3;
        letter-spacing: 0.72px;
        margin-left: 12px;
        cursor: pointer;
        line-height: 20px;
        vertical-align: middle;
        font-size: 0;
        i {
          display: inline-block;
          vertical-align: middle;
        }
        span {
          font-size: 12px;
          display: inline-block;
          vertical-align: middle;
        }
      }
    }
    .right {
      height: 100%;
      // float: left;
      // width: @rightWidth;
      // margin-right: -100%;
      // position: relative;
      text-align: right;
      position: absolute;
      top: 0;
      right: 0;
      .abnormal {
        user-select: none;
        font-size: 13px;
        color: #f55c4e;
        line-height: 17px;
        letter-spacing: 0.74px;
        cursor: pointer;
      }
    }
    .btn-back {
      width: 90px;
      height: 32px;
      padding: 0;
      font-size: 13px;
      letter-spacing: 0.78px;
      background-color: #f5f6fa;
      border-radius: 12px;
      border: none;
      margin-left: 24px;
      cursor: pointer;
    }
    // 开始授课按钮
    .btn-start {
      width: 88px;
      height: 40px;
      background: @themeBlue;
      border-radius: 12px;
      border: none;
      padding: 0;
      color: #fff;
      font-size: 14px;
      font-weight: 600;
      margin-left: 18px;
      margin-top: 6px;
      vertical-align: top;
      line-height: 19px;
      &:hover {
        color: #fff;
        background: rgba(96, 73, 255, 0.9);
      }
    }
    // 全屏
    .btn-fullscreen {
      width: 124px;
      height: 40px;
      background: #646c95;
      vertical-align: top;
      line-height: 12px;
      font-size: 16px;
      font-weight: 600;
      border-radius: 12px;
      color: #ffffff;
      margin: 6px 16px 0;
      border: 0;
      &:hover {
        background: #585f83;
      }
      & img {
        width: 16px;
        height: 16px;
        vertical-align: middle;
        margin-right: 5px;
      }
      & span {
        vertical-align: middle;
      }
    }
  }
  // 侧边
  .el-aside {
    width: 120px !important;
    border-right: 1px solid #ebedf5;
    position: relative;
    .el-scrollbar {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
    }
    .navleft {
      margin: 0;
      dt {
        font-size: 14px;
        padding-left: 20px;
        margin-bottom: 25px;
        border-left: 3px solid @themeBlue;
        .title {
          letter-spacing: 1px;
          line-height: 16px;
          padding: 5px 0;
        }
        .inline {
          width: 110px;
        }
      }
      dd {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        letter-spacing: 1.12px;
        width: 70px;
        height: 64px;
        margin: 20px auto;
        cursor: pointer;
        span {
          display: inline-block;
          vertical-align: middle;
        }
      }
      dd:nth-of-type(3),
      dd:nth-of-type(4) {
        // width: 70px;
      }
      &.bk {
        // padding-top: 34px;
        dt {
          .title {
            color: @themeBlue;
          }
        }
        dd {
          &:hover {
            background: rgba(196, 213, 255, 0.12);
            border-radius: 4px;
          }
          &.active {
            color: #fff;
            background: @themeBlue;
            box-shadow: 0px 2px 7px 0px rgba(96, 73, 255, 0.2);
            border-radius: 15px;
          }
        }
      }
      &.sk {
        // margin-top: 45px;
        padding-bottom: 100px;
        dt {
          border-left: 3px solid #9400ff;
          .title {
            color: #9400ff;
          }
        }
        dd {
          &:hover {
            background: rgba(213, 184, 255, 0.1);
            border-radius: 4px;
          }
          &.active {
            color: #fff;
            background: @themeBlue;
            box-shadow: 0px 2px 7px 0px rgba(148, 0, 255, 0.13);
            border-radius: 15px;
          }
        }
        dd:nth-of-type(1) {
          width: 70px;
        }
        dd:nth-of-type(3) {
          width: 70px;
        }
      }
    }
    .footer {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      text-align: center;
      button {
        width: 100px;
        height: 38px;
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px 3px 6px 0px rgba(168, 168, 168, 0.24);
        border-radius: 2px;
        margin-bottom: 28px;
        font-size: 14px;
        color: #646c95;
        letter-spacing: 1px;
        font-weight: bold;
        cursor: pointer;
        &:hover {
          box-shadow: 0 3px 6px 0 rgba(111, 138, 163, 0.16);
        }
      }
      .inline {
        height: 4px;
        overflow: hidden;
        &::before {
          content: "";
          display: block;
          width: 140px;
          height: 8px;
          background: rgba(73, 124, 240, 1);
          border-radius: 6px;
          margin: 0 auto;
          margin-bottom: -4px;
        }
      }
    }
    .file-list__toggle {
      font-size: 12px;
      position: absolute;
      width: 22px;
      height: 64px;
      background: rgba(153, 188, 220, 0.6);
      right: 0px;
      bottom: 0;
      color: #fff;
      line-height: 14px;
      border-radius: 12px 0px 0px 0px;
      cursor: pointer;
      text-align: center;
      padding-top: 2px;
      user-select: none;
      z-index: 100;
    }
  }
  // 内容
  .el-main {
    position: relative;
    padding: 0;
    .el-scrollbar {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
    }
  }
  .dialog-header {
    &-img {
      width: 50px;
      margin-right: 14px;
      height: auto;
      img {
        width: 100%;
      }
    }
    &-title {
      font-size: 20px;
      font-weight: bold;
      color: #444444;
      line-height: 26px;
    }
    &-tip {
      font-size: 15px;
      color: #646c95;
      margin-top: 11px;
    }
  }
  .dialog-content {
    margin-top: 33px;
    // padding: 0 48px;
    img {
      max-width: 100%;
    }
  }
  /deep/ .el-dialog__header {
    padding: 0;
  }
  /deep/.el-dialog--center .el-dialog__body {
    padding: 36px 30px;
  }
  /deep/ .el-dialog__footer {
    padding-top: 0px;
  }
}

/deep/ .swiper-slide {
  width: 100% !important;
  padding: 0 48px;
  box-sizing: border-box;
}
.swiper-button-next {
  right: 0;
  width: 18px;
  height: 60px;
  .gen-icon(icon_switch_right);
}
.swiper-button-prev {
  left: 0;
  width: 18px;
  height: 60px;
  .gen-icon(icon_switch_left);
}
.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
  opacity: 0;
}
// 图标
.icon-navtop-back {
  width: 12px;
  height: 12px;
  margin-right: 6px;
  .gen-icon(navtop_back);
}
.icon-navtop-switch {
  width: 12px;
  height: 12px;
  margin-right: 2px;
  .gen-icon(navtop_switch);
}
// 左侧菜单图标
// @navleftIcons: bksp, kj, kqyx, zy, ja;
// .loop(@list, @i:1, @val:extract(@list, @i)) when(length(@navleftIcons)>=@i) {
//   .icon-navleft-@{val} {
//     .gen-icon(~"navleft_@{val}");
//   }
//   .navleft .active .icon-navleft-@{val} {
//     .gen-icon(~"navleft_@{val}_ed");
//   }
//   .loop(@list, (@i+1));
// }
// .loop(@navleftIcons);
@navleftIcons: bksp, kj, kqyx, zy, ja, ktsl, ktzl;
.loop(@list, @i:1, @val:extract(@list, @i)) when(length(@navleftIcons)>=@i) {
  .icon-navleft-@{val} {
    .gen-icon(~"icon_nav_@{val}");
  }
  .navleft .active .icon-navleft-@{val} {
    .gen-icon(~"icon_nav_active_@{val}");
  }
  .loop(@list, (@i+1));
}
.loop(@navleftIcons);
[class^="icon-navleft-"] {
  width: 24px;
  height: 24px;
  padding: 1px 0;
}
// 提示小助手
.prompt-assistant {
  position: relative;
  width: 220px;
  height: 87px;
  .msg {
    color: #fff;
    font-size: 14px;
    letter-spacing: 0.8px;
    line-height: 19px;
    padding-top: 6px;
    margin-bottom: 18px;
    margin-left: 10px;
  }
  button {
    width: 98px;
    height: 34px;
    background: linear-gradient(
      180deg,
      rgba(103, 156, 246, 1) 0%,
      rgba(64, 114, 238, 1) 100%
    );
    border-radius: 5px;
    margin-left: 15px;
    color: #fff;
    font-size: 14px;
    letter-spacing: 0.14px;
    cursor: pointer;
  }
  img {
    position: absolute;
    right: 10px;
    width: 34px;
    bottom: -18px;
  }
}
</style>

<style lang="less">
.el-tooltip__popper.tooltip--prompt-assistant {
  // box-shadow: -2px -2px 8px 0px rgba(173, 188, 204, 0.2),
  //   2px 2px 8px 0px rgba(173, 188, 204, 0.16);
  border-radius: 8px;
  margin-top: 13px;
  &.is-dark {
    background-color: #424041;
  }
  .popper__arrow {
    background-color: #424041;
    border-radius: 4px;
    border: none;
    width: 16px;
    height: 16px;
    transform: rotate(45deg);
    margin-left: -8px;
    &::after {
      display: none;
    }
  }
}

.navleft-container {
  // position: relative;
  .navleft-no-resource {
    position: absolute;
    top: -8px;
    right: -20px;
    padding: 2px 10px;
    height: fit-content;
    background: #f1f1f1;
    border-radius: 0px 12px 0px 12px;
    font-size: 12px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #aaaaaa;
  }
}

@media only screen and (min-height: 560px) and (max-height: 760px) {
  .classroom {
    min-height: calc(100vh - 52px) !important;
  }
  .navleft-container {
    // position: relative;
    height: 100%;
  }
  .classroom .el-aside {
    min-height: 508px;
    max-height: 708px;
    // .navleft {
    //   dd {
    //     margin-top: 11px !important;
    //   }
    // }
  }
  .navleft.bk {
    padding-top: 14px !important;
    dt {
      margin-bottom: 10px !important;
    }
    dd {
      margin: 10px auto !important;
    }
    .navleft-no-resource {
      top: -4px !important;
    }
  }
  .navleft.sk {
    // padding-top: 4px !important;
    margin-top: 31px !important;
    padding-bottom: 56px !important;
    dt {
      margin-bottom: 10px !important;
    }
    dd {
      margin: 10px auto !important;
    }
  }
  .footer {
    button {
      margin-bottom: 14px !important;
    }
  }
}
@media only screen and (max-height: 559px) {
  .classroom {
    min-height: 400px !important;
  }
  .classroom .el-aside {
    .navleft {
      dd {
        margin-top: 11px !important;
      }
      .navleft-no-resource {
        top: -4px !important;
      }
    }
  }
  .navleft.bk {
    padding-top: 14px !important;
  }
  .navleft.sk {
    // padding-top: 4px !important;
    margin-top: 31px !important;
    margin-bottom: 56px !important;
  }
  .footer {
    // bottom: 30px !important;
    button {
      margin-bottom: 14px !important;
    }
  }
}
</style>
