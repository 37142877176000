<template>
  <div class="float-menu">
    <div ref="menuNav" class="menu-nav move_nav" @click="packUp">
      <i class="icon-nav"></i>
    </div>
    <div ref="menuContent" class="menu-content move_content">
      <!-- <div @click.stop="$emit('random-questions')">
        <img class="toolicon" :src="robit" alt="">
        随机提问
      </div>
      <div @click.stop="$emit('call-roll')">
        <img class="toolicon" :src="calltheroll" alt="">
        点名
      </div> -->
      <div
        v-if="fullscreen"
        class="painting"
        :class="{
          disabled: status,
        }"
        @click.stop="!status && $emit('switch-status')"
        @mousedown.stop
      >
        <i class="icon-painting"></i>绘制
      </div>
      <div
        class="clear"
        v-if="count > 1 || fullscreen"
        @click.stop="onClear"
        @mousedown.stop
      >
        <i class="icon-clear"></i>清除本页
      </div>
      <!-- <div @click.stop="$emit('call-roll')">
        <img class="toolicon" :src="switchFile" alt="">
        切换课件
      </div> -->
      <div @click.stop="switchFileList" @mousedown.stop>
        <img class="toolicon" :src="switchFile" alt="" />
        切换课件
      </div>
    </div>
    <div
      ref="fileListBox"
      class="menu-file-list"
      @mousedown.stop
      v-loading="fileListLoading"
    >
      <div class="file-list-box">
        <div class="file-type-title">集团资源</div>
        <div v-if="fileData.standardList.length === 0" class="no-file">
          暂无内容
        </div>
        <div
          v-for="item in fileData.standardList"
          :key="item.resourceId"
          class="file-item"
          :class="resourceId == item.resourceId ? 'file-item-disabled' : ''"
          @click.stop="
            resourceId == item.resourceId ? '' : changeFilePage(item)
          "
        >
          <img :src="item.resourceUrl | getFileType" alt="" class="file-icon" />
          <!-- <tooltip-over
            :content="item.resourceName"
            :class="
              resourceId == item.resourceId ? 'file-name-active' : 'file-name'
            "
            :singleAble="false"
          ></tooltip-over> -->
          <p
            :class="
              resourceId == item.resourceId ? 'file-name-active' : 'file-name'
            "
            slot="reference"
          >
            {{ item.resourceName }}
          </p>
          <!-- <p class="file-name" slot="reference">{{ item.resourceName }}</p>
          </el-popover> -->
        </div>
        <div class="file-type-title">学校资源</div>
        <div v-if="fileData.collectiveList.length === 0" class="no-file">
          暂无内容
        </div>
        <div
          v-for="item in fileData.collectiveList"
          :key="item.resourceId"
          class="file-item"
          :class="resourceId == item.resourceId ? 'file-item-disabled' : ''"
          @click.stop="
            resourceId == item.resourceId ? '' : changeFilePage(item)
          "
        >
          <img :src="item.resourceUrl | getFileType" alt="" class="file-icon" />
          <!-- <tooltip-over
            :content="item.resourceName"
            :class="
              resourceId == item.resourceId ? 'file-name-active' : 'file-name'
            "
            :singleAble="false"
          ></tooltip-over> -->
          <p
            :class="
              resourceId == item.resourceId ? 'file-name-active' : 'file-name'
            "
            slot="reference"
          >
            {{ item.resourceName }}
          </p>
        </div>
        <div class="file-type-title">个人资源</div>
        <div v-if="fileData.personalList.length === 0" class="no-file">
          暂无内容
        </div>
        <div
          v-for="item in fileData.personalList"
          :key="item.resourceId"
          class="file-item"
          :class="resourceId == item.resourceId ? 'file-item-disabled' : ''"
          @click.stop="
            resourceId == item.resourceId ? '' : changeFilePage(item)
          "
        >
          <img :src="item.resourceUrl | getFileType" alt="" class="file-icon" />
          <!-- <tooltip-over
            :content="item.resourceName"
            :class="
              resourceId == item.resourceId ? 'file-name-active' : 'file-name'
            "
            :singleAble="false"
          ></tooltip-over> -->
          <p
            :class="
              resourceId == item.resourceId ? 'file-name-active' : 'file-name'
            "
            slot="reference"
          >
            {{ item.resourceName }}
          </p>
        </div>
      </div>
    </div>
    <div class="switch-tips" v-if="showTip" @mousedown.stop>
      <div class="tip-close">
        <img
          src="@/assets/images/icon/close_btn_icon@2x.png"
          @click.stop="hiddenTip"
          alt=""
        />
      </div>
      <div class="tip-content">
        <img class="tip-bg" src="@/assets/images/icon/tip_bg@2x.png" alt="" />
        <div class="tip-text">
          <img
            class="tip-click-icon"
            src="@/assets/images/icon/click_icon@2x.png"
            alt=""
          />
          <div class="tip-text-content">点击这里可以切换课件</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Whiteboard from "whiteboard";
import { queryResource, noticeCheck } from "@/api/classroom";
import robit from "@/assets/images/icon/icon_taach_pick@2x.png";
import calltheroll from "@/assets/images/icon/icon_taach_list@2x.png";
import switchFile from "@/assets/images/icon/icon_switch_file.png";
import tooltipOver from "@/components/biz-components/tooltipOver/index.vue";
export default {
  name: "float-menu",
  components: {
    // tooltipOver,
  },
  props: {
    /**
     * 绘制状态
     */
    status: {
      type: Boolean,
      default: false,
    },
    /**
     * 是否全屏状态
     */
    fullscreen: Boolean,
  },
  data() {
    return {
      robit: robit,
      calltheroll: calltheroll,
      switchFile: switchFile,
      showTip: false,
      w: null, // 白板对象
      pointer: 0, // 指针
      count: 1, // 记录数
      navOpen: false,
      fileListOpen: false,
      id: this.$route.query.id,
      fileData: { standardList: [], collectiveList: [], personalList: [] },
      resourceId: this.$route.query.resourceId,
      fileListLoading: false, // 文件列表的loading
    };
  },
  created() {
    this.noticeCheck();
  },
  filters: {
    getFileType(resourceUrl) {
      let ppt = require("@/assets/images/icon/pic_format_ppt@2x.png");
      let doc = require("@/assets/images/icon/pic_format_word@2x.png");
      let excel = require("@/assets/images/icon/excel.png");
      let pdf = require("@/assets/images/icon/pdf.png");
      let mp4 = require("@/assets/images/icon/pic_format_mp4@2x.png");
      let otherfile = require("@/assets/images/icon/other-file.png");
      let type =
        resourceUrl.indexOf("pptx") > -1 ||
        resourceUrl.indexOf("ppt") > -1 ||
        resourceUrl.indexOf("PPTX") > -1 ||
        resourceUrl.indexOf("PPT") > -1
          ? ppt
          : resourceUrl.indexOf("doc") > -1 ||
            resourceUrl.indexOf("docx") > -1 ||
            resourceUrl.indexOf("DOC") > -1 ||
            resourceUrl.indexOf("DOCX") > -1
          ? doc
          : resourceUrl.indexOf("mp4") > -1 ||
            resourceUrl.indexOf("MP4") > -1 ||
            resourceUrl.indexOf("mov") > -1 ||
            resourceUrl.indexOf("MOV") > -1
          ? mp4
          : resourceUrl.indexOf("pdf") > -1 || resourceUrl.indexOf("PDF") > -1
          ? pdf
          : resourceUrl.indexOf("xls") > -1 ||
            resourceUrl.indexOf("xlsx") > -1 ||
            resourceUrl.indexOf("XLS") > -1 ||
            resourceUrl.indexOf("XLSX") > -1
          ? excel
          : otherfile;
      return type;
    },
  },
  methods: {
    /**
     * 初始化
     */
    init(w) {
      if (!(w instanceof Whiteboard)) {
        console.warn("参数错误：非白板对象");
        return;
      }
      this.w = w;
      // 历史改变
      this.w.$on("change-history", (pointer, count) => {
        this.pointer = w.pointer;
        this.count = w.store.length;
      });
    },
    /**
     * 清除白板
     */
    onClear() {
      if (!this.w) return;
      this.w.clear();
      this.$message.success("清除成功");
      if (!this.status) {
        console.log("qingchu");
        document.querySelector("#newwpsfile iframe").focus();
      }
    },
    /**
     * 点击收起或者展开
     */
    packUp() {
      this.$nextTick(() => {
        this.navOpen = !this.navOpen;
        if (!this.navOpen) {
          this.$refs.menuContent.classList.remove("move_on_content");
          this.$refs.menuContent.classList.add("move_content");
          this.$refs.menuNav.classList.remove("move_on_nav");
          this.$refs.menuNav.classList.add("move_nav");
          this.$refs.fileListBox.classList.remove("move_file_list");
          this.$refs.fileListBox.classList.add("move_on_file_list");
          this.showTip = false;
          if (this.fileListOpen) {
            this.fileListOpen = !this.fileListOpen;
          }
        } else {
          this.$refs.menuContent.classList.remove("move_content");
          this.$refs.menuContent.classList.add("move_on_content");
          this.$refs.menuNav.classList.remove("move_nav");
          this.$refs.menuNav.classList.add("move_on_nav");
        }
      });
    },
    switchFileList() {
      this.$nextTick(() => {
        this.showTip = false;
        this.fileListOpen = !this.fileListOpen;
        if (!this.fileListOpen) {
          this.$refs.fileListBox.classList.remove("move_file_list");
          this.$refs.fileListBox.classList.add("move_on_file_list");
        } else {
          this.$refs.fileListBox.classList.remove("move_on_file_list");
          this.$refs.fileListBox.classList.add("move_file_list");
          this.fileListLoading = true;
          queryResource("KJ", {
            knowledgeId: this.id,
            originTenantId: this.$route.query.originTenantId,
          })
            .then((res) => {
              this.fileData = res.data;
              this.fileListLoading = false;
            })
            .catch(() => {
              this.fileListLoading = false;
            });
        }
      });
    },
    noticeCheck() {
      let params = { pageRoute: this.$route.path, versionCode: 19 };
      noticeCheck(params).then((res) => {
        if (res.data) {
          this.showTip = false;
          return;
        } else {
          this.$refs.menuContent.classList.remove("move_content");
          this.$refs.menuContent.classList.add("move_on_content");
          this.$refs.menuNav.classList.remove("move_nav");
          this.$refs.menuNav.classList.add("move_on_nav");
          this.showTip = true;
        }
      });
    },
    // 关闭提示
    hiddenTip() {
      this.showTip = false;
    },
    changeFilePage(item) {
      console.log("resourceId");
      // let query = Object.assign({resourceId: resourceId}, this.$route.query)
      let query = { ...this.$route.query };
      query.resourceId = item.resourceId;
      query.version = item.version;
      this.$router.replace({ query });
      this.resourceId = item.resourceId;
      let obj = {
        resource: item,
        resourceId: item.resourceId,
        classId: this.$route.query.classId,
        format: item.format,
      };
      this.$emit("changewpscurryPage", 1, 1);
      // this.$parent.selectBack(obj,1)
      this.$emit("selectBack", obj, 1);
      this.packUp();
    },
  },
};
</script>

<style lang="less" scoped>
.float-menu {
  .no-file {
    text-align: center;
    color: #666666;
    font-size: 12px;
  }
  .switch-tips {
    position: absolute;
    right: 5px;
    bottom: 15px;
    .tip-close {
      text-align: right;
      font-size: 0;
      img {
        cursor: pointer;
        width: 22px;
        height: 22px;
        margin-right: 10px;
        margin-bottom: 8px;
      }
    }
    .tip-content {
      cursor: default;
      width: 224px;
      height: 48px;
      position: relative;
      .tip-bg {
        width: 224px;
        height: 48px;
      }
      .tip-text {
        position: absolute;
        top: 10px;
        display: flex;
        align-items: center;
        .tip-click-icon {
          width: 18px;
          height: 19px;
          margin-left: 16px;
        }
        .tip-text-content {
          margin-left: 8px;
          color: #fff;
          font-size: 12px;
          font-weight: 400;
        }
      }
    }
  }
  .file-list-box {
    padding: 10px 0;
    -webkit-box-shadow: 2px 5px 5px 0 rgb(85 84 103 / 30%);
    box-shadow: 2px 5px 5px 0 rgb(85 84 103 / 30%);
  }
  .toolicon {
    width: 36px;
    height: 36px;
  }
  .menu-nav {
    width: 22px;
    height: 96px;
    background: #f6f7f9;
    border-radius: 5px 0 0 5px;
    position: absolute;
    top: calc(50% - 48px);
    left: -21px;
    cursor: pointer;
    i {
      width: 10px;
      vertical-align: middle;
      margin-top: 28px;
      margin-right: 0;
      margin-left: 6px;
    }
  }
  .menu-content {
    width: 88px;
    background: #f8f8f8;
    // border-radius: 10px;
    color: #333;
    padding: 18px 12px 12px 12px;
    box-shadow: 2px 5px 5px 0 rgba(85, 84, 103, 0.3);
    box-sizing: border-box;
    font-size: 14px;
    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      line-height: 26px;
      margin-bottom: 20px;
      cursor: pointer;
    }
  }
  .icon-common () {
    display: inline-block;
    width: 36px;
    height: 36px;
    vertical-align: top;
    margin-right: 10px;
  }
  .icon-nav {
    .icon-common();
    .gen-icon(icon_teaching_open);
  }
  .icon-question {
    .icon-common();
    .gen-icon(icon_taach_pick);
    width: 36px;
    height: 36px;
    margin-right: 0;
  }
  .icon-call {
    .icon-common();
    .gen-icon(icon_taach_list);
    width: 42px;
    height: 42px;
    margin-right: 0;
  }
  .icon-painting {
    .icon-common();
    .gen-icon(icon_taach_draw);
    width: 36px;
    height: 36px;
    margin-right: 0;
  }
  .icon-clear {
    .icon-common();
    margin-right: 0;
    .gen-icon(painting_Eliminate);
  }
  .disabled {
    opacity: 0.2;
    cursor: no-drop;
  }
  .menu-file-list {
    width: 230px;
    height: 256px;
    background-color: #fff;
    position: fixed;
    right: -230px;
    margin-top: -80px;
    overflow-y: auto;
    .file-type-title {
      padding: 10px 14px;
      height: 14px;
      font-size: 14px;
      font-weight: bold;
      color: #333333;
    }
    .file-item {
      display: flex;
      padding: 10px 14px;
      cursor: pointer;
      align-items: center;
      &:hover {
        background-color: #f2f2f2;
      }
      .file-icon {
        width: 26px;
        height: 26px;
        margin-right: 8px;
      }
      .file-name {
        font-size: 12px;
        color: #666666;
        word-break: break-word;
        word-wrap: break-word;
      }
      .file-name-active {
        font-size: 12px;
        color: #6049ff;
      }
    }
    .file-item-disabled {
      display: flex;
      padding: 10px 14px;
      cursor: not-allowed;
      align-items: center;
      &:hover {
        background-color: #f2f2f2;
      }
    }
  }
  .menu-file-list::-webkit-scrollbar {
    display: none;
  }
}
.en-status {
  .float-menu .menu-content > div {
    font-size: 15px;
    text-align: center;
  }
}
.move_file_list {
  -webkit-animation-name: move_file_list;
  animation-name: move_file_list;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
.move_on_file_list {
  -webkit-animation-name: move_on_file_list;
  animation-name: move_on_file_list;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
.move_content {
  -webkit-animation-name: move_content;
  animation-name: move_content;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
.move_nav {
  -webkit-animation-name: move_nav;
  animation-name: move_nav;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
.move_on_content {
  -webkit-animation-name: move_on_content;
  animation-name: move_on_content;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
.move_on_nav {
  -webkit-animation-name: move_on_nav;
  animation-name: move_on_nav;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
@-webkit-keyframes move_nav {
  from {
    opacity: 1;
  }
  to {
    -webkit-transform: translateX(88px);
    transform: translateX(98px);
  }
}
@keyframes move_nav {
  from {
    opacity: 1;
  }
  to {
    -webkit-transform: translateX(88px);
    transform: translateX(88px);
  }
}
@-webkit-keyframes move_file_list {
  from {
    opacity: 1;
  }
  to {
    right: 100px;
    // display: none;
  }
}
@keyframes move_file_list {
  from {
    opacity: 1;
  }
  to {
    right: 100px;
    // display: none;
  }
}
@-webkit-keyframes move_on_file_list {
  from {
    opacity: 1;
  }
  to {
    right: -230px;
    display: block;
  }
}
@keyframes move_on_file_list {
  from {
    opacity: 1;
  }
  to {
    right: -230px;
    display: block;
  }
}
@-webkit-keyframes move_on_nav {
  from {
    opacity: 1;
    -webkit-transform: translateX(88px);
    transform: translateX(88px);
  }
  to {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes move_on_nav {
  from {
    opacity: 1;
    -webkit-transform: translateX(88px);
    transform: translateX(88px);
  }
  to {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@-webkit-keyframes move_on_content {
  from {
    opacity: 1;
    -webkit-transform: translateX(88px);
    transform: translateX(88px);
  }
  to {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes move_on_content {
  from {
    opacity: 1;
    -webkit-transform: translateX(88px);
    transform: translateX(88px);
  }
  to {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@-webkit-keyframes move_content {
  from {
    opacity: 1;
  }
  to {
    -webkit-transform: translateX(88px);
    transform: translateX(88px);
  }
}
@keyframes move_content {
  from {
    opacity: 1;
  }
  to {
    -webkit-transform: translateX(88px);
    transform: translateX(88px);
  }
}
</style>
