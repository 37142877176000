var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{staticClass:"compare-lession",attrs:{"width":"720px","visible":_vm.dialogVisible,"title":_vm.$t('el.classroomWps.selectResourcesCompare'),"custom-class":"compare-custom-class","close-on-click-modal":false,"close-on-press-escape":false},on:{"close":_vm.closeDialog}},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.fileListLoading),expression:"fileListLoading"}],ref:"fileListBox",staticClass:"menu-file-list",on:{"mousedown":function($event){$event.stopPropagation();}}},[_c('el-tabs',{staticClass:"tab_manage_menu",on:{"tab-click":_vm.changeResourceTab},model:{value:(_vm.activeResourceName),callback:function ($$v) {_vm.activeResourceName=$$v},expression:"activeResourceName"}},_vm._l((_vm.resourceTabList),function(item){return _c('el-tab-pane',{key:item.name,attrs:{"name":item.name,"label":_vm.$t(item.label)}})}),1),_c('div',{staticClass:"file-list-box"},[_c('div',{staticClass:"file-type-title"},[_vm._v(" "+_vm._s(_vm.$t("el.classroomWps.groupResource"))+" ")]),(_vm.fileData.standardList.length === 0)?_c('div',{staticClass:"no-file"},[_vm._v(" "+_vm._s(_vm.$t("el.common.noResources"))+" ")]):_vm._e(),_vm._l((_vm.fileData.standardList),function(item){return _c('div',{key:item.resourceId,staticClass:"file-item",class:_vm.resourceId == item.resourceId ||
          _vm.compareResourceId == item.resourceId ||
          _vm.getResourceType(item.format) === 'otherType'
            ? 'file-item-disabled'
            : '',on:{"click":function($event){$event.stopPropagation();_vm.resourceId == item.resourceId ||
          _vm.compareResourceId == item.resourceId
            ? ''
            : _vm.changeFilePage(item)}}},[_c('img',{staticClass:"file-icon",attrs:{"src":_vm._f("getFileType")(item.resourceUrl),"alt":""}}),_c('div',{staticClass:"flex flex-m",staticStyle:{"width":"100%"}},[_c('p',{class:_vm.resourceId == item.resourceId ? 'file-name-active' : 'file-name',style:(_vm.resourceId == item.resourceId
                ? 'width: 450px;'
                : 'width: 540px;' + 'text-align: left;'),attrs:{"slot":"reference"},slot:"reference"},[_c('tooltip-over',{attrs:{"content":item.resourceName,"singleAble":true}})],1),(_vm.resourceId == item.resourceId)?_c('span',{staticClass:"current-resource"},[_vm._v(_vm._s(_vm.$t("el.classroomWps.currentResource")))]):_vm._e(),(_vm.compareResourceId == item.resourceId)?_c('el-image',{staticClass:"icon-checked",attrs:{"src":_vm.iconChecked}}):_vm._e()],1)])}),_c('div',{staticClass:"file-type-title"},[_vm._v(" "+_vm._s(_vm.$t("el.classroomWps.schoolResource"))+" ")]),(_vm.fileData.collectiveList.length === 0)?_c('div',{staticClass:"no-file"},[_vm._v(" "+_vm._s(_vm.$t("el.common.noResources"))+" ")]):_vm._e(),_vm._l((_vm.fileData.collectiveList),function(item){return _c('div',{key:item.resourceId,staticClass:"file-item",class:_vm.resourceId == item.resourceId ||
          _vm.compareResourceId == item.resourceId ||
          _vm.getResourceType(item.format) === 'otherType'
            ? 'file-item-disabled'
            : '',on:{"click":function($event){$event.stopPropagation();_vm.resourceId == item.resourceId ||
          _vm.compareResourceId == item.resourceId
            ? ''
            : _vm.changeFilePage(item)}}},[_c('img',{staticClass:"file-icon",attrs:{"src":_vm._f("getFileType")(item.resourceUrl),"alt":""}}),_c('div',{staticClass:"flex flex-m",staticStyle:{"width":"100%"}},[_c('p',{class:_vm.resourceId == item.resourceId ? 'file-name-active' : 'file-name',style:(_vm.resourceId == item.resourceId
                ? 'width: 450px;'
                : 'width: 540px;' + 'text-align: left;'),attrs:{"slot":"reference"},slot:"reference"},[_c('tooltip-over',{attrs:{"content":item.resourceName,"singleAble":true}})],1),(_vm.resourceId == item.resourceId)?_c('span',{staticClass:"current-resource"},[_vm._v(_vm._s(_vm.$t("el.classroomWps.currentResource")))]):_vm._e(),(_vm.compareResourceId == item.resourceId)?_c('el-image',{staticClass:"icon-checked",attrs:{"src":_vm.iconChecked}}):_vm._e()],1)])}),_c('div',{staticClass:"file-type-title"},[_vm._v(" "+_vm._s(_vm.$t("el.classroomWps.myResourse"))+" ")]),(_vm.fileData.personalList.length === 0)?_c('div',{staticClass:"no-file"},[_vm._v(" "+_vm._s(_vm.$t("el.common.noResources"))+" ")]):_vm._e(),_vm._l((_vm.fileData.personalList),function(item){return _c('div',{key:item.resourceId,staticClass:"file-item",class:_vm.resourceId == item.resourceId ||
          _vm.compareResourceId == item.resourceId ||
          _vm.getResourceType(item.format) === 'otherType'
            ? 'file-item-disabled'
            : '',on:{"click":function($event){$event.stopPropagation();_vm.resourceId == item.resourceId ||
          _vm.compareResourceId == item.resourceId
            ? ''
            : _vm.changeFilePage(item)}}},[_c('img',{staticClass:"file-icon",attrs:{"src":_vm._f("getFileType")(item.resourceUrl),"alt":""}}),_c('div',{staticClass:"flex flex-m",staticStyle:{"width":"100%"}},[_c('p',{class:_vm.resourceId == item.resourceId ? 'file-name-active' : 'file-name',style:(_vm.resourceId == item.resourceId
                ? 'width: 450px;'
                : 'width: 540px;' + 'text-align: left;'),attrs:{"slot":"reference"},slot:"reference"},[_c('tooltip-over',{attrs:{"content":item.resourceName,"singleAble":true}})],1),(_vm.resourceId == item.resourceId)?_c('span',{staticClass:"current-resource"},[_vm._v(_vm._s(_vm.$t("el.classroomWps.currentResource")))]):_vm._e(),(_vm.compareResourceId == item.resourceId)?_c('el-image',{staticClass:"icon-checked",attrs:{"src":_vm.iconChecked}}):_vm._e()],1)])})],2)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }