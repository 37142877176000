<template>
  <!-- 知识点切换 -->
  <el-dialog
    v-loading="loading"
    width="640px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :visible.sync="visible"
  >
    <div class="ks-header" slot="title">
      <div class="ks-title">{{ $t("el.common.chooseKnowledge") }}</div>
      <div class="ks-subtitle">
        {{ courseInfo.courseName }}
        <span v-if="courseInfo.isTry" class="details-course-try">{{
          $t("el.common.onTrial")
        }}</span>
      </div>
    </div>
    <div class="ks-main">
      <el-scrollbar class="el-scrollbar_x" noresize ref="scroll">
        <div class="ks-node__wrap" v-if="list && list.length">
          <dl v-for="(item, index) in list" :key="index" class="ks-node tb">
            <dt class="flex flex-m">
              <i class="icon-knowledge-title"></i>

              <div class="details-chapter-title">
                <div>U{{ index + 1 }}</div>
                <div style="margin-top: -10px" class="u-line"></div>
              </div>

              <span>{{ item.knowledgeName }}</span>
            </dt>
            <template v-if="item.children && item.children.length">
              <dd
                v-for="(node, i) in item.children"
                :key="i"
                class="flex flex-m"
                :class="{
                  disabled: node.id == currentId,
                  selected: node.id == selectedItem.id,
                }"
                @click="!(node.id == currentId) && isSwitch && selected(node)"
              >
                <div
                  class="ks-node__name t-over2"
                  :style="
                    !isSwitch && node.canClick
                      ? 'width: calc(100% - 125px)'
                      : 'width: 100%'
                  "
                  @click="prepare(node)"
                >
                  <div class="flex flex-m">
                    <span>{{ i + 1 + "、" + node.knowledgeName }}</span>
                    <span v-if="node.isTeacherTrain" class="ks-node__train">{{
                      $t("el.classroomWps.teacherInstructions")
                    }}</span>
                    <el-image
                      style="
                        width: 16px;
                        height: 16px;
                        margin-left: auto;
                        display: block;
                      "
                      v-if="!node.canClick"
                      :src="iconLock"
                    ></el-image>
                  </div>
                </div>
                <div
                  v-if="!isSwitch && node.canClick"
                  class="ks-node__handle td"
                >
                  <!-- 备课 -->
                  <span
                    v-if="prepareShow"
                    v-check-bsk-permission="{
                      handler: prepare,
                      handlerArgs: [node],
                      args: {
                        courseId: courseId,
                        originTenantId: originTenantId,
                      },
                    }"
                    class="ks-node__btn"
                    >{{ $t("el.courseDetails.prepareLessons") }}</span
                  >
                  <!-- 授课 -->
                  <span
                    class="ks-node__btn"
                    :class="[
                      node.standardCount + node.personCount < 1
                        ? 'disabled-class'
                        : '',
                    ]"
                    v-check-bsk-permission="{
                      handler: give,
                      handlerArgs: [node],
                      args: {
                        courseId: courseId,
                        originTenantId: originTenantId,
                      },
                    }"
                    >{{ $t("el.courseDetails.teachingLessons") }}</span
                  >
                </div>
              </dd>
            </template>
          </dl>
        </div>
      </el-scrollbar>
    </div>
  </el-dialog>
</template>

<script>
import { queryCourseInfo, queryKnowledge } from "@/api/classroom/index.js";
import { getCourseDetail } from "@/api/courseList/index";
export default {
  name: "knowledge-switch",
  props: {
    /**
     * 是否是切换
     */
    isSwitch: {
      type: Boolean,
      default: false,
    },
    /**
     * 当前知识点id
     */
    currentId: String,
    /**
     * 是否显示备课
     */
    prepareShow: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      courseName: "",
      visible: false, // 是否显示
      list: [
        // 知识点列表
      ],
      loading: false, // 正在努力加载数据
      courseInfo: {}, // 课程信息
      selectedItem: {}, // 选中的item
      iconLock: require("@/assets/images/icon/icon-lock.png"),
    };
  },
  methods: {
    init(courseId, originTenantId, originTenantType) {
      this.courseId = courseId;
      this.originTenantId = originTenantId;
      this.originTenantType = originTenantType;
      if (this.loading) return;
      this.loading = true;
      var loading = this.$loading({
        text: this.$t("el.teaching.loadingData"),
      });
      getCourseDetail({ courseId, originTenantId, originTenantType })
        .then(({ data }) => {
          this.courseInfo = data;
          return queryKnowledge({
            courseId,
          });
        })
        .then(({ data }) => {
          let idx = 0;
          data.forEach((element) => {
            element.children.forEach((ele) => {
              ele.idx = idx;
              idx = idx + 1;
              if (ele.idx < 3 || !this.courseInfo.isTry) {
                ele.canClick = true;
              } else {
                ele.canClick = false;
              }
            });
          });
          this.list = data;
          this.visible = true;
          this.$nextTick(() => {
            let div = this.$refs["scroll"].$refs["wrap"];
            var disabled = document.querySelector(".disabled");
            if (disabled) {
              let top = disabled.offsetTop;
              div.scrollTo({ top: top });
            }
          });
        })
        .finally(() => {
          this.loading = false;
          loading.close();
        });
    },
    prepare(item) {
      if (!this.prepareShow) {
        return;
      }
      if (!item.canClick) {
        this.$message.warning("试用课程仅可使用前三个知识点");
        return;
      }
      this.$emit(
        "prepareBack",
        item,
        this.courseId,
        this.originTenantId,
        this.originTenantType
      );
    },
    give(item) {
      console.log("item====", item);
      if (item.standardCount + item.personCount < 1) {
        return;
      } else {
        console.log("this.courseId====", this.courseId);
        this.$emit("giveBack", item, this.courseId, this.courseInfo);
      }
      // this.$emit('prepareBack')
      this.visible = false;
    },
    /**
     * 选择知识点
     * @param {*} item 知识点信息
     */
    selected(item) {
      if (!item.canClick) {
        // this.$message.warning("试用课程仅可使用前三个知识点");
        return;
      }
      this.selectedItem = item;
      this.$emit("selected", item);
      this.visible = false;
    },
  },
};
</script>

<style lang="less" scoped>
// 修改模态框样式
/deep/ .el-dialog {
  text-align: left;
  border-radius: 10px;
  .el-dialog__header {
    border-bottom: 1px solid #ebedf5;
  }
  .el-dialog__body {
    padding: 0;
  }
}
// 修改滚动条样式
/deep/ .el-scrollbar__thumb {
  background-color: @themeBlue;
}
/deep/ .el-scrollbar__bar.is-vertical {
  width: 3px;
}

.details-chapter {
  &-icon {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }
  &-title {
    flex-shrink: 0;
    font-size: 16px;
    font-family: PingFang SC;
    // font-weight: 400;
    // color: #131313;
    line-height: 28px;
    margin-right: 16px;
    height: 28px;
  }
}

.u-line {
  width: 100%;
  height: 5px;
  background: #cde2fc;
  border-radius: 3px;
}

.icon-knowledge-title {
  width: 26px;
  height: 26px;
  margin-right: 13px;
  line-height: 19px;
  vertical-align: middle;
  .gen-icon(knowledge-title);
  flex-shrink: 0;
}
// 头部
.ks-header {
  padding: 30px 30px 10px 30px;
  .details-course-try {
    padding: 3px 12px;
    height: fit-content;
    background: rgba(0, 118, 244, 0.1);
    border-radius: 12px;
    font-size: 12px;
    font-family: PingFang-SC-Bold, PingFang-SC;
    font-weight: bold;
    color: #0076f4;
    margin-left: 12px;
  }
}
// 标题
.ks-title {
  color: #444444;
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
  margin-bottom: 8px;
}
// 副标题标题
.ks-subtitle {
  text-align: center;
  color: #131313;
  font-size: 16px;
  font-weight: bold;
  line-height: 21px;
}
// 内容
.ks-main {
  height: 583px;
  .el-scrollbar {
    width: 100% !important;
    height: 100% !important;
  }
}
.ks-node__name {
  margin-left: 31px !important;
  .ks-node__train {
    padding: 3px 12px;
    height: fit-content;
    background: rgba(90, 83, 245, 0.15);
    border-radius: 20px;
    margin-left: 14px;

    font-size: 12px;
    font-family: PingFang-SC-Bold, PingFang-SC;
    font-weight: bold;
    color: #5a53f5;
    min-width: 50px;
    box-sizing: border-box;
  }
}
// 知识节点
.ks-node {
  margin: 0;
  margin-bottom: 12px;
  &__wrap {
    padding: 24px 36px 8px 32px;
  }
  dt {
    font-size: 16px;
    font-weight: bold;
    color: #374051;
    line-height: 19px;
    letter-spacing: 1px;
    margin-bottom: 10px;
  }
  dd {
    width: 100%;
    &.disabled {
      cursor: no-drop;
      font-weight: bold;
      .ks-node__name {
        cursor: no-drop;
        margin-left: 31px;
        color: @themeBlue;
        &:hover {
          background: none;
        }
      }
    }
    &.selected {
      .ks-node__name {
        margin-left: 31px;
        color: @themeBlue;
        font-weight: bold;
      }
    }
  }
  &__name {
    font-size: 14px;
    color: #444444;
    line-height: 19px;
    letter-spacing: 1px;
    padding: 7px 8px 7px 8px;
    margin-left: 20px;
    cursor: pointer;
    &:hover {
      // color: @themeBlue;
      background: rgba(73, 124, 240, 0.08);
    }
    & > div {
      overflow: hidden;
    }
  }
  &__handle {
    width: 118px;
    vertical-align: middle;
    height: 100%;
    font-size: 14px;
    color: #646c95;
    line-height: 19px;
    letter-spacing: 1px;
    text-align: right;
  }
  &__btn {
    color: #646c95;
    cursor: pointer;
    font-weight: 700;
    &:hover {
      color: @themeBlue;
    }
    & + & {
      margin-left: 22px;
    }
  }
}
.disabled-class {
  font-size: 14px;
  color: rgba(212, 212, 212, 1) !important;
  line-height: 19px;
  font-weight: normal !important;
  letter-spacing: 1px;
}
</style>
