<template>
  <div
    class="whiteboard"
    :class="currentToolName"
    ref="whiteboard"
    :style="{
      'pointer-events': status ? 'auto' : 'none',
    }"
  >
    <!-- <canvas v-for="(item,index) in boardlist"
         :key="index" v-show="item == number" ref="canvas"></canvas> -->
    <canvas ref="canvas" @load="onload"></canvas>
  </div>
</template>

<script>
import Whiteboard from "whiteboard";
export default {
  name: "whiteboard",
  props: {
    /**
     * 绘制状态
     */
    status: {
      type: Boolean,
      default: false,
    },
    number: {
      type: Number,
    }, // 当前页码
  },
  data() {
    return {
      w: [], // 画板对象
      currentW: null, // 当前白板实例对象
      wList: [], // 白板每页实例列表
      currentTool: null, // 当前使用的工具
      currentToolName: null, // 工具名称
    };
  },
  watch: {
    number: "init",
  },
  mounted() {
    this.onResize();
    this.init();
    window.addEventListener("resize", this.onResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize);
  },
  // updated(){
  //   this.onResize();
  //   this.init();
  // },
  methods: {
    /**
     * 初始化画板
     */
    init() {
      this.$nextTick(() => {
        const canvas = this.$refs.canvas;
        const index = this.number - 1;
        if (this.currentW) {
          // 当前已有的画笔
          // this.currentW.$off('change-tool');
          this.currentW.disable();
        }
        if (!this.wList[index]) {
          // 清除画布
          var w = canvas.width;
          var h = canvas.height;
          canvas.getContext("2d").clearRect(0, 0, w, h);
          // 未初始化实例
          this.currentW = new Whiteboard(canvas);
          this.wList[index] = this.currentW;
        } else {
          // 已初始化过
          this.currentW = this.wList[index];
          // 替换dom实例和canvas绘画对象
          this.currentW.$el = canvas;
          this.currentW.ctx = canvas.getContext("2d");
          this.currentW._loadDataUrl(); // 重新加载当前缓存
        }
        this.currentW.$on("change-tool", (tool) => {
          this.currentTool = tool;
          this.currentToolName = tool.name;
        });
        this.currentW.enable();
        this.$emit("loaded", this.currentW);
      });
      // 已初始化过
      // if (this.w) return;
      // console.log("页码22",this.number)
      // this.w = new Whiteboard(this.$refs.canvas);
      // this.$emit('loaded', this.w); // 加载完成
      // this.w.$on('change-tool', (tool) => {
      //   this.currentTool = tool;
      //   this.currentToolName = tool.name;
      // });
    },
    /**
     * 尺寸变化,canvas大小跟着变化
     */
    onResize() {
      this.$refs.canvas.width = document.documentElement.clientWidth;
      this.$refs.canvas.height = document.documentElement.clientHeight;
      if (!this.currentW) return;
      // 如果canvas尺寸变化，参数配置会重置
      this.currentW.setSize(this.currentW.size);
      this.currentW.setColor(this.currentW.color);
    },
    onload() {
      console.log("canvas初始化");
    },
  },
};
</script>

<style lang="less" scoped>
.whiteboard {
  canvas {
    width: 100%;
    height: 100%;
  }
  &.pen {
    cursor: url("~@/assets/images/icon/icon_pencil.ico") 0 32, auto;
  }
  &.rubber {
    cursor: url("~@/assets/images/icon/icon_rubber.ico") 0 32, auto;
  }
}
</style>
