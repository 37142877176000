var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"tools",staticClass:"tools"},[_c('div',{staticClass:"pen",class:{
      active: _vm.currentToolName === 'pen',
    },on:{"click":function($event){$event.stopPropagation();return _vm.changeTool('pen')},"mousedown":function($event){$event.stopPropagation();}}},[_c('i',{staticClass:"icon-pen"})]),_c('div',{staticClass:"rubber",class:{
      active: _vm.currentToolName === 'rubber',
    },on:{"click":function($event){$event.stopPropagation();return _vm.changeTool('rubber')},"mousedown":function($event){$event.stopPropagation();}}},[_c('i',{staticClass:"icon-rubber"})]),_c('div',{staticClass:"palette",on:{"mousedown":function($event){$event.stopPropagation();}}},[_c('el-popover',{attrs:{"placement":"top","width":"176","trigger":"manual"},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('div',{staticClass:"allselect"},[_c('div',{staticClass:"selectcolor red",on:{"click":function($event){return _vm.choosecolor(1)}}},[(_vm.color == '#e8322f')?_c('img',{staticClass:"chooseimg",attrs:{"src":_vm.duipic,"alt":""}}):_vm._e()]),_c('div',{staticClass:"selectcolor green",on:{"click":function($event){return _vm.choosecolor(2)}}},[(_vm.color == '#1fbb7d')?_c('img',{staticClass:"chooseimg",attrs:{"src":_vm.duipic,"alt":""}}):_vm._e()]),_c('div',{staticClass:"selectcolor blue",on:{"click":function($event){return _vm.choosecolor(3)}}},[(_vm.color == '#4178f9')?_c('img',{staticClass:"chooseimg",attrs:{"src":_vm.duipic,"alt":""}}):_vm._e()]),_c('div',{staticClass:"selectcolor white",on:{"click":function($event){return _vm.choosecolor(4)}}},[(_vm.color == '#ffffff')?_c('img',{staticClass:"chooseimg",attrs:{"src":_vm.duipic,"alt":""}}):_vm._e()]),_c('div',{staticClass:"selectcolor black",on:{"click":function($event){return _vm.choosecolor(5)}}},[(_vm.color == '#000000')?_c('img',{staticClass:"chooseimg",attrs:{"src":_vm.duipic,"alt":""}}):_vm._e()])]),_c('div',{staticClass:"colorselect",class:{
          red: _vm.color == '#e8322f',
          green: _vm.color == '#1fbb7d',
          blue: _vm.color == '#4178f9',
          white: _vm.color == '#ffffff',
          black: _vm.color == '#000000',
        },attrs:{"slot":"reference"},on:{"click":function($event){_vm.visible = !_vm.visible}},slot:"reference"})])],1),_c('div',{staticClass:"slider",on:{"mousedown":function($event){$event.stopPropagation();}}},[_c('el-slider',{attrs:{"min":5,"max":20,"show-tooltip":_vm.displaytips},model:{value:(_vm.size),callback:function ($$v) {_vm.size=$$v},expression:"size"}})],1),_c('div',{staticClass:"inline"}),_c('div',{staticClass:"back",class:{ 'opacity-60': !_vm.isCanBack },on:{"click":function($event){$event.stopPropagation();return _vm.handleBack.apply(null, arguments)},"mousedown":function($event){$event.stopPropagation();}}},[_c('i',{staticClass:"icon-back"}),_c('span',[_vm._v(_vm._s(_vm.$t("el.teaching.undo")))])]),_c('div',{staticClass:"restore",class:{ 'opacity-60': !_vm.isCanRestore },on:{"click":function($event){$event.stopPropagation();return _vm.handleRestore.apply(null, arguments)},"mousedown":function($event){$event.stopPropagation();}}},[_c('i',{staticClass:"icon-restore"}),_c('span',[_vm._v(_vm._s(_vm.$t("el.teaching.recovery")))])]),_c('div',{staticClass:"clear",on:{"click":function($event){$event.stopPropagation();return _vm.handleClear.apply(null, arguments)},"mousedown":function($event){$event.stopPropagation();}}},[_c('i',{staticClass:"icon-clear"}),_c('span',[_vm._v(_vm._s(_vm.$t("el.teaching.clearPage")))])]),_c('div',{staticClass:"inline"}),_c('div',{staticClass:"switch",on:{"click":function($event){$event.stopPropagation();return _vm.$emit('switch-status')},"mousedown":function($event){$event.stopPropagation();}}},[_c('span',[_vm._v(_vm._s(_vm.statusText))])])])
}
var staticRenderFns = []

export { render, staticRenderFns }