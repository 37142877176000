/**
 * 备课-课件接口文件
 */
import axios from "@/plugins/axios.js";

/**
 * 权限列表
 * @return {Promise}
 */
export function getQueryResourceAndClass(resourceType, data) {
  return axios.get(`/resourceManage/queryResourceAndClassV2/${resourceType}`, {
    params: data,
  });
}
