import { MessageBox } from "element-ui";
import { queryKnowledgeInfo } from "@/api/classroom";

export default {
  data() {
    return {
      knowledgeData: {}, // 知识点数据
    };
  },
  beforeRouteEnter(to, from, next) {
    // 在渲染该组件的对应路由被 confirm 前调用
    // 不！能！获取组件实例 `this`
    // 因为当守卫执行前，组件实例还没被创建
    var id = to.query.id;
    if (!id) {
      MessageBox.alert("缺少参数无法正常打开备课页面", "提示", {
        type: "warning",
        showClose: false,
        closeOnPressEscape: false,
      })
        .then(() => {
          next({ ...from });
        })
        .catch(() => {
          next({ ...from });
        });
    } else {
      queryKnowledgeInfo({
        knowledgeId: id,
      })
        .then(({ data }) => {
          // 校验课程知识点是否被停过，是否有权限。
          if (data.courseStatus || data.knowledgeStatus) {
            // 停用
            next({
              name: "Resource",
            });
          } else if (!data.courseAuth) {
            // 无权限
            next({
              name: "Auth",
            });
          } else {
            next((vm) => {
              vm.knowledgeData = data || {};
            });
          }
        })
        .catch(() => {
          MessageBox.confirm("数据加载失败", "提示", {
            type: "warning",
            showClose: false,
            closeOnPressEscape: false,
            closeOnClickModal: false,
            confirmButtonText: "重新加载",
            cancelButtonText: "返回",
          })
            .then(() => {
              next({ ...to });
            })
            .catch(() => {
              next({ ...from });
            });
        });
    }
  },
};
