<template>
  <!-- 预览状态wps -->
  <div id="wpsFile"></div>
</template>
<script>
import Vue from "vue";
import {
  setWordListener,
  setPPTListener,
  setPDFListener,
  setXLSListener,
  removeWordListener,
  removePPTListener,
  removeXLSListener,
  removePDFListener,
} from "../../utils/wps-listener";
import { mapActions } from "vuex";
import { getToken } from "@/core/auth";
export default {
  props: {
    // eslint-disable-next-line vue/require-prop-type-constructor
    wpsUrl: "",
    // eslint-disable-next-line vue/require-prop-type-constructor
    token: "",
    isShowTopArea: {
      type: Boolean,
      default: true,
    },
    // eslint-disable-next-line vue/require-prop-type-constructor
    type: "",
    wpscurryPage: {
      type: Number,
    },
    /**
     * 课件对象
     */
    courseware: {
      type: Object,
      default: () => {},
    },
    // wps 参数
    wpsParams: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      teststate: true,
      simpleMode: true, // 是否开启简易模式
      officeType: "",
      jssdk: null,
    };
  },
  watch: {
    wpsParams() {
      console.log("watch wpsUrl==========");
      this.openWps(this.wpsParams, getToken());
    },
  },
  mounted() {
    console.log("mounted 1==========", this.courseware);
    this.openWps(this.wpsParams, getToken());
    // this.openWps(this.aaa, this.bbb);
    // this.test();
  },
  beforeDestroy() {
    console.log("beforeDestroy=============");
    this.removeListiner(this.jssdk);
  },
  methods: {
    ...mapActions(["setPageAction"]),
    async pageChange() {
      let _this = this;
      // eslint-disable-next-line no-undef
      await wps.ready();
      // eslint-disable-next-line no-undef
      const app = wps.Application;
      app.Sub.SlideSelectionChanged = async (curryPage) => {
        console.log("切换到：", curryPage);
      };
    },
    async openWps(wpsParams, token) {
      let _this = this;
      this.jssdk = this.wps.init({
        officeType: wpsParams.w_file_format,
        mode: _this.simpleMode ? "simple" : "normal",
        mount: document.querySelector("#wpsBox"),
        appId: wpsParams.w_app_id,
        fileId: wpsParams.w_file_id,
        token,
        customArgs: wpsParams,
        pptOptions: {
          isInSafeMode: true, // 安全模式
          isShowRemarkView: false, // 是否显示备注视图
        },
        commonOptions: {
          isShowTopArea: _this.isShowTopArea, // 隐藏顶部区域(头部和工具栏)
          isBrowserViewFullscreen: true, // 是否在浏览器区域全屏 true禁止全屏
          isIframeViewFullscreen: true, // 是否在 iframe 区域内全屏
        },
        wordOptions: {
          isShowBottomStatusBar: false, // 是否展示底部状态栏
        },
        commandBars: [
          {
            cmbId: "MoreMenus", // 组件 控制更多菜单隐藏
            attributes: {
              visible: false, // 隐藏组件
            },
          },
          {
            cmbId: "FloatQuickHelp", // 组件 页面定制对象：右下角帮助（金小獴）
            attributes: {
              visible: false, // 隐藏组件
            },
          },
          {
            cmbId: "TabPrintPreview", // 组件 页面定制对象：打印
            attributes: {
              visible: false, // 隐藏组件
            },
          },
          {
            cmbId: "HeaderHistoryMenuBtn", // 组件 顶部状态栏-历史记录菜单按钮
            attributes: {
              visible: false, // 隐藏组件
            },
          },
          {
            cmbId: "WPPPcCommentButton", // 组件 演示-底部工具栏-评论按钮
            attributes: {
              visible: false, // 隐藏组件
            },
          },
          {
            cmbId: "TabReviewTab", // 组件 演示-工具栏-审阅按钮
            attributes: {
              visible: false, // 隐藏组件
            },
          },
          {
            cmbId: "TabReviewWord", // 组件 word工具栏-审阅按钮
            attributes: {
              visible: false, // 隐藏组件
            },
          },
          {
            cmbId: "ContextMenuConvene", // 组件 word-右键-召唤在线协助者
            attributes: {
              visible: false, // 隐藏组件
            },
          },
          {
            cmbId: "BookMarkContextMenu", // 组件 文字-右键-插入书签
            attributes: {
              visible: false, // 隐藏组件
            },
          },
          {
            cmbId: "PlayComponentToolbar", // 组件 全屏播放时 Toolbar
            attributes: {
              visible: false, // 隐藏组件
            },
          },
        ],
      });

      this.jssdk.on("fileOpen", (data) => {
        console.log("文件打开", data);
        if (!data.success) {
          console.log("文件打开失败");
        } else {
          this.officeType = data.fileInfo.officeType || "";
        }
      });
      this.jssdk.setToken({ token });
      Vue.prototype.wpsConfig = this.jssdk; // 全局定义wpsConfig，通过this.wpsConfig调用改变wps
      let app = this.jssdk.Application;
      await this.jssdk.ready();
      console.log("wps========", this.jssdk);
      console.log("officeType======", this.officeType);
      switch (this.officeType) {
        case "w":
          console.log("wwwwwwww=========");
          setWordListener(this.jssdk, this.handleActionCallback);
          break;
        case "p":
          setPPTListener(this.jssdk, this.handleActionCallback);
          break;
        case "s":
          setXLSListener(this.jssdk, this.handleActionCallback);
          break;
        case "f":
          setPDFListener(this.jssdk, this.handleActionCallback);
          break;

        default:
          setWordListener(this.jssdk, this.handleActionCallback);
          break;
      }
      console.log("_this.type==========", _this.type);
      if (_this.type === 1) {
        // type=1 word
        return;
      }
      if (_this.type === 2) {
        // type=2 ppt
        _this.setwps(this.jssdk);
      }
    },

    handleActionCallback() {
      console.log("handleActionCallback===========");
      this.setPageAction(this.courseware);
    },

    removeListiner(wps) {
      switch (this.officeType) {
        case "w":
          removeWordListener(wps, this.handleActionCallback);
          break;
        case "p":
          removePPTListener(wps, this.handleActionCallback);
          break;
        case "s":
          removeXLSListener(wps, this.handleActionCallback);
          break;
        case "f":
          removePDFListener(wps, this.handleActionCallback);
          break;

        default:
          removeWordListener(wps, this.handleActionCallback);
          break;
      }
    },

    async setwps(wps) {
      let _this = this;
      // await wps.ready();
      const app = wps.Application;
      // Slide 设置对象
      let SlideShowSettings = await app.ActivePresentation.SlideShowSettings;
      // 进入幻灯片播放模式
      await SlideShowSettings.Run();
      await _this.$emit("setinitdata", true);
      console.log(77);
      // app.Enum.PpToolType.pcPlayingMenu = "pc_play_hover_link";
      // await console.log("app", app.Enum.PpToolType)
      // await app.ActivePresentation.SlideShowWindow.View.Exit();
      // 演示文稿对象
      const presentation = await app.ActivePresentation;
      // 幻灯片对象
      const slides = await presentation.Slides;
      // 获取总页数
      const count = await slides.Count;
      await _this.$emit("changewpscurryPage", count, 2);
      // 获取当前页码
      const curryPage = await app.ActivePresentation.SlideShowWindow.View.Slide
        .SlideIndex;
      if (curryPage != 1) {
        // 跳转到指定页
        await _this.$emit("setbuttonstate", false, true);
        await app.ActivePresentation.SlideShowWindow.View.GotoSlide(1);
      } else {
        if (count == 1) {
          _this.$emit("setbuttonstate", false, false);
          this.$parent.setbuttonstate(false, false);
        }
        if (count > 1) {
          if (curryPage == 1) {
            _this.$emit("setbuttonstate", false, true);
          } else {
            if (curryPage == count) {
              _this.$emit("setbuttonstate", true, false);
            } else {
              _this.$emit("setbuttonstate", true, true);
            }
          }
        }
      }
      const SlideShowWindow = await app.ActivePresentation.SlideShowWindow;
      // 视图对象
      const view = await SlideShowWindow.View;
      // view.ShowPage = true; // 【播放模式】显示页码
      // app.ActivePresentation.SlideShowSettings.SetMiniThumbnailVisible(true)//可以跳出页码预览
      const menu = await app.Enum.PpToolType.pcPlayingMenu; // 右键菜单
      view.SetToolVisible(menu, false);
      // 监听退出幻灯片全屏播放
      app.Sub.SlideShowEnd = async () => {
        console.log("退出全屏播放");
        _this.$emit("setinitdata", false);
      };
      // 监听进入幻灯片全屏播放
      app.Sub.SlideShowBegin = async () => {
        console.log("进入全屏播放");
        view.ShowPage = true;
        _this.$emit("setinitdata", true);
      };
      // eslint-disable-next-line require-atomic-updates
      app.Sub.SlideSelectionChanged = async (curryPage) => {
        // _this.wpscurryPage = curryPage;
        await _this.$emit("changewpscurryPage", curryPage, 1);
        const presentation = await app.ActivePresentation;
        // 幻灯片对象
        const slides = await presentation.Slides;
        // 获取总页数
        const count = await slides.Count;
        const nowPage = await app.ActivePresentation.SlideShowWindow.View.Slide
          .SlideIndex;
        if (count == 1) {
          _this.$emit("setbuttonstate", false, false);
        }
        if (count > 1) {
          if (nowPage == 1 || _this.wpscurryPage == 1) {
            _this.$emit("setbuttonstate", false, true);
          } else {
            if (nowPage == count || _this.wpscurryPage == count) {
              _this.$emit("setbuttonstate", true, false);
            } else {
              _this.$emit("setbuttonstate", true, true);
            }
          }
        }
      };
    },
    /**
     * ppt格式设置
     */
    // async setwps(wps) {
    //   let _this = this;
    //   await wps.ready();
    //   const app = wps.Application;
    //   // Slide 设置对象
    //   let SlideShowSettings = await app.ActivePresentation.SlideShowSettings;
    //   // 进入幻灯片播放模式
    //   await SlideShowSettings.Run();
    //   await _this.$emit("setinitdata", true);

    //   // app.Enum.PpToolType.pcPlayingMenu = "pc_play_hover_link";
    //   // await console.log("app", app.Enum.PpToolType)
    //   // await app.ActivePresentation.SlideShowWindow.View.Exit();
    //   // 演示文稿对象
    //   const presentation = await app.ActivePresentation;
    //   // 幻灯片对象
    //   const slides = await presentation.Slides;
    //   // 获取总页数
    //   const count = await slides.Count;
    //   // 获取当前页码
    //   const curryPage = await app.ActivePresentation.SlideShowWindow.View.Slide
    //     .SlideIndex;
    //   if (curryPage != 1) {
    //     // 跳转到指定页
    //     await _this.$emit("setbuttonstate", false, true);
    //     await app.ActivePresentation.SlideShowWindow.View.GotoSlide(1);
    //   } else {
    //     if (count == 1) {
    //       _this.$emit("setbuttonstate", false, false);
    //     }
    //     if (count > 1) {
    //       if (curryPage == 1) {
    //         _this.$emit("setbuttonstate", false, true);
    //       } else {
    //         if (curryPage == count) {
    //           _this.$emit("setbuttonstate", true, false);
    //         } else {
    //           _this.$emit("setbuttonstate", true, true);
    //         }
    //       }
    //     }
    //   }
    //   const SlideShowWindow = await app.ActivePresentation.SlideShowWindow;
    //   // 视图对象
    //   const view = await SlideShowWindow.View;
    //   // view.ShowPage = true; // 【播放模式】显示页码
    //    const menu =await app.Enum.PpToolType.pcPlayingMenu // 右键菜单
    //   view.SetToolVisible(menu, false);
    //   // 监听退出幻灯片全屏播放
    //   app.Sub.SlideShowEnd = async () => {
    //     console.log("退出全屏播放");
    //     _this.$emit("setinitdata", false);
    //   };
    //   // 监听进入幻灯片全屏播放
    //   app.Sub.SlideShowBegin = async () => {
    //     console.log("进入全屏播放");
    //     // view.ShowPage = true;
    //     _this.$emit("setinitdata", true);
    //   };
    //   app.Sub.SlideSelectionChanged = async (curryPage) => {
    //     _this.wpscurryPage = curryPage;
    //     const presentation = await app.ActivePresentation;
    //     // 幻灯片对象
    //     const slides = await presentation.Slides;
    //     // 获取总页数
    //     const count = await slides.Count;
    //     const nowPage = await app.ActivePresentation.SlideShowWindow.View.Slide
    //       .SlideIndex;
    //     if (count == 1) {
    //       _this.$emit("setbuttonstate", false, false);
    //     }
    //     if (count > 1) {
    //       if (nowPage == 1 || _this.wpscurryPage == 1) {
    //         _this.$emit("setbuttonstate", false, true);
    //       } else {
    //         if (nowPage == count || _this.wpscurryPage == count) {
    //           _this.$emit("setbuttonstate", true, false);
    //         } else {
    //           _this.$emit("setbuttonstate", true, true);
    //         }
    //       }
    //     }
    //   };
    //   // await app.ActivePresentation.SlideShowWindow.View.Exit();
    //   // const moreMenus = await app.CommandBars("MoreMenus"); // 控制更多菜单隐藏
    //   // moreMenus.Visible = false;
    //   // const FloatQuickHelp = await app.CommandBars("FloatQuickHelp"); // 页面定制对象：右下角帮助（金小獴）
    //   // FloatQuickHelp.Visible = false;
    //   // const TabPrintPreview = await app.CommandBars("TabPrintPreview"); // 页面定制对象：打印
    //   // TabPrintPreview.Visible = false;
    //   // const HeaderHistoryMenuBtn = await app.CommandBars(
    //   //   "HeaderHistoryMenuBtn"
    //   // ); // 页面定制对象：顶部状态栏-历史记录菜单按钮
    //   // HeaderHistoryMenuBtn.Visible = false;
    //   // const WPPPcCommentButton = await app.CommandBars("WPPPcCommentButton"); // 页面定制对象：演示-底部工具栏-评论按钮
    //   // WPPPcCommentButton.Visible = false;
    //   // const TabReviewTab = await app.CommandBars("TabReviewTab"); // 页面定制对象：演示-工具栏-审阅按钮
    //   // TabReviewTab.Visible = false;

    //   // const TabThumbNumber = await app.CommandBars("TabThumbNumber"); // 页面定制对象：演示-工具栏-审阅按钮
    //   // TabThumbNumber.Visible = false;
    // },
    /**
     * Word格式设置
     */
    async setwordwps(wps) {
      await wps.ready();
      let app = wps.Application;
      const moreMenus = await app.CommandBars("MoreMenus"); // 控制更多菜单隐藏
      moreMenus.Visible = false;
      const FloatQuickHelp = await app.CommandBars("FloatQuickHelp"); // 页面定制对象：右下角帮助（金小獴）
      FloatQuickHelp.Visible = false;
      const TabPrintPreview = await app.CommandBars("TabPrintPreview"); // 页面定制对象：打印
      TabPrintPreview.Visible = false;
      const TabReviewWord = await app.CommandBars("TabReviewWord"); // 页面定制对象：word工具栏-审阅按钮
      TabReviewWord.Visible = false;
      const ContextMenuConvene = await app.CommandBars("ContextMenuConvene"); // 页面定制对象：word-右键-召唤在线协助者
      ContextMenuConvene.Visible = false;
      const BookMarkContextMenu = await app.CommandBars("BookMarkContextMenu"); // 页面定制对象：文字-右键-插入书签
      BookMarkContextMenu.Visible = false;
      const HeaderHistoryMenuBtn = await app.CommandBars(
        "HeaderHistoryMenuBtn"
      ); // 页面定制对象：顶部状态栏-历史记录菜单按钮
      HeaderHistoryMenuBtn.Visible = false;
    },
  },
};
</script>

<style lang="less" scoped>
// #wpsFile{
//   color: #fff;
//   z-index: 1000;
// }
</style>
